import React, { useState } from "react";

import PropTypes from "prop-types";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { useEffect } from "react";
import { useP } from "../services/i18n";
import clsx from "clsx";

export default function Pagination({
  length = 25,
  totalItems = [],
  paginatedItems = [],
  setPaginatedItems,
}) {
  const p = useP();

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const startIndex = (currentPage - 1) * length;
    const endIndex = startIndex + length;
    setPaginatedItems(totalItems.slice(startIndex, endIndex));
  }, [currentPage, length, totalItems]);

  const totalPages = Math.ceil(totalItems.length / length);

  const handlePrevClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * length + 1;
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden">
        <button
          type="button"
          className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
          onClick={handlePrevClick}
        >
          {p.t("commons.previous")}
        </button>
        <button
          type="button"
          onClick={handleNextClick}
          className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          {p.t("commons.next")}
        </button>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between space-x-4">
        <div>
          <p className="text-sm text-gray-700">
            {p.t("commons.showing")}{" "}
            <span className="font-medium">{startIndex}</span>{" "}
            {p.t("commons.to")}{" "}
            <span className="font-medium">{paginatedItems.length}</span>{" "}
            {p.t("commons.of")}{" "}
            <span className="font-medium">{totalItems?.length}</span>{" "}
            {p.t("commons.results")}
          </p>
        </div>
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <button
              type="button"
              onClick={handlePrevClick}
              className="relative inline-flex items-center rounded-l-md px-1 py-1 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">{p.t("commons.previous")}</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>

            {/* <a
              href="#"
              aria-current="page"
              className="relative z-10 inline-flex items-center bg-blue-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              1
            </a> */}
            {pageNumbers.map((page) => (
              <button
                key={page}
                onClick={() => handlePageClick(page)}
                disabled={page === currentPage}
                className={clsx(
                  "relative",
                  page === currentPage
                    ? "z-10 inline-flex items-center bg-blue-600 px-3 py-1 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    : "hidden items-center px-3 py-1 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex"
                )}
              >
                {page}
              </button>
            ))}
            <button
              type="button"
              onClick={handleNextClick}
              className="relative inline-flex items-center rounded-r-md px-1 py-1 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">{p.t("commons.next")}</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}

Pagination.propTypes = {
  length: PropTypes.number,
  totalItems: PropTypes.array,
  paginatedItems: PropTypes.array,
  setPaginatedItems: PropTypes.func,
};
