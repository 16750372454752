import { Fragment, useContext } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  LockClosedIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import AuthModal from "./AuthModal/AuthModal";
import { authContext } from "../../AuthContext";
import { useP } from "../../services/i18n";
import ProfileDropdown from "./Profile/ProfileDropdown";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";

export default function Navigation() {
  const p = useP();

  const { authModaleStatus, setAuthModaleStatus, loggedInUser } =
    useContext(authContext);

  const openSignInModal = () => {
    setAuthModaleStatus("signIn");
  };

  const openRegisterModal = () => {
    setAuthModaleStatus("register");
  };

  const onClose = () => {
    setAuthModaleStatus("hidden");
  };

  const soon = () => {
    toast.error(p.t("toasts.itemLocked"));
  };

  return (
    <>
      <Popover className="relative bg-white shadow-sm">
        <div className="mx-auto max-w-7xl px-6">
          <div className="flex items-center justify-between border-b-0 border-slate-100 py-6 md:justify-start md:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <Link
                to="/"
                className="flex items-center justify-center space-x-4"
              >
                {/* <span className="sr-only">Your Company</span> */}
                {/* <img className="h-8 w-auto sm:h-12" src="/logo.png" alt="" /> */}
                <span className="flex flex-col uppercase font-extrabold text-xl text-center">
                  <span className="text-transparent bg-clip-text bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
                    Catapult
                  </span>
                  {/* <span className="text-sm"> by Fragment</span> */}
                </span>
              </Link>
            </div>
            <div className="-my-2 -mr-2 md:hidden">
              <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-slate-400 hover:bg-slate-100 hover:text-slate-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-slate-500">
                <span className="sr-only">Open menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
            <Popover.Group as="nav" className="hidden space-x-10 md:flex">
              <Link
                to="/discover"
                className="text-base font-medium text-slate-500 hover:text-slate-900"
              >
                {p.t("navigation.tabs.explore")}
              </Link>
              <Link
                to="/claim"
                reloadDocument={true}
                className="text-base font-medium text-slate-500 hover:text-slate-900"
              >
                {p.t("navigation.tabs.claim")}
              </Link>
              {loggedInUser && (
                <Link
                  to="/account"
                  reloadDocument={true}
                  className="text-base font-medium text-slate-500 hover:text-slate-900"
                >
                  {p.t("navigation.tabs.wallet")}
                </Link>
              )}
            </Popover.Group>
            <div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
              {loggedInUser ? (
                <>
                  <ProfileDropdown soon={soon} />
                </>
              ) : (
                <>
                  <button
                    className="whitespace-nowrap text-base font-medium text-slate-500 hover:text-slate-900"
                    onClick={openSignInModal}
                    type="button"
                  >
                    {p.t("navigation.tabs.signIn")}
                  </button>
                  <button
                    className="ml-8 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-slate-800 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-slate-700"
                    onClick={openRegisterModal}
                    type="button"
                  >
                    {p.t("navigation.tabs.register")}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden z-50"
          >
            <div className="divide-y-2 divide-slate-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="px-5 pt-5 pb-6">
                <div className="flex items-center justify-between">
                  <div>
                    <Link
                      to="/"
                      className="flex items-center justify-center space-x-4"
                    >
                      <span className="flex flex-col uppercase font-extrabold text-xl text-center">
                        <span className="text-transparent bg-clip-text bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
                          Catapult
                        </span>
                      </span>
                    </Link>
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-slate-400 hover:bg-slate-100 hover:text-slate-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-slate-500">
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
              </div>
              {loggedInUser && (
                <div className={"space-y-6 py-6 px-5"}>
                  <div className="-m-3 flex items-center rounded-md p-3 ">
                    <span className="ml-3 text-base font-normal text-gray-900">
                      {loggedInUser?.email}
                    </span>
                  </div>
                </div>
              )}

              <div className="space-y-6 py-6 px-5">
                <div className="">
                  <nav className="grid gap-y-8">
                    <Link
                      to="/explore"
                      className="-m-3 flex items-center rounded-md p-3 hover:bg-gray-50"
                    >
                      <span className="ml-3 text-base font-medium text-gray-900">
                        {p.t("navigation.tabs.explore")}
                      </span>
                    </Link>
                    <Link
                      to="/claim"
                      reloadDocument={true}
                      className="-m-3 flex items-center rounded-md p-3 hover:bg-gray-50"
                    >
                      <span className="ml-3 text-base font-medium text-gray-900">
                        {p.t("navigation.tabs.claim")}
                      </span>
                    </Link>
                    {loggedInUser && (
                      <Link
                        to="/account"
                        reloadDocument={true}
                        className="-m-3 flex items-center rounded-md p-3 hover:bg-gray-50"
                      >
                        <span className="ml-3 text-base font-medium text-gray-900">
                          {p.t("navigation.tabs.wallet")}
                        </span>
                      </Link>
                    )}
                  </nav>
                </div>
              </div>
              <div className="space-y-6 py-6 px-5">
                {loggedInUser ? (
                  <>
                    <button
                      type="button"
                      onClick={soon}
                      href="/settings"
                      className="-m-3 flex items-center rounded-md p-3 hover:bg-gray-50"
                    >
                      <LockClosedIcon className="w-5 h-5 text-gray-400" />
                      <span className="ml-3 text-base font-medium text-gray-900">
                        {p.t("navigation.tabs.profile.settings")}
                      </span>
                    </button>
                    <button
                      type="button"
                      onClick={soon}
                      href="/needHelp"
                      className="-m-3 flex items-center rounded-md p-3 hover:bg-gray-50"
                    >
                      <LockClosedIcon className="w-5 h-5 text-gray-400" />
                      <span className="ml-3 text-base font-medium text-gray-900">
                        {p.t("navigation.tabs.profile.needHelp")}
                      </span>
                    </button>
                    <Link
                      to="/logout"
                      className="-m-3 flex items-center rounded-md p-3 hover:bg-gray-50"
                    >
                      <span className="ml-3 text-base font-medium text-gray-900">
                        {p.t("navigation.tabs.profile.logout")}
                      </span>
                    </Link>
                  </>
                ) : (
                  <div>
                    <button
                      type="button"
                      className="flex w-full items-center justify-center rounded-md border border-transparent bg-slate-800 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-slate-700"
                      onClick={openRegisterModal}
                    >
                      {p.t("navigation.tabs.register")}
                    </button>
                    <p className="mt-6 text-center text-base font-medium text-slate-500">
                      {p.t("navigation.tabs.alreadyHaveAccount")}{" "}
                      <button
                        type="button"
                        className="text-slate-600 hover:text-slate-500"
                        onClick={openSignInModal}
                      >
                        {p.t("navigation.tabs.signIn")}
                      </button>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
      <AuthModal open={authModaleStatus !== "hidden"} onClose={onClose} />
    </>
  );
}
