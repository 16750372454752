import { useMutation } from "@apollo/client";
import React, { useContext, useState } from "react";
import { authContext } from "../../../AuthContext";
import { SUBSCRIBE_TO_NEWSLETTER } from "../../../queries";
import { useP } from "../../../services/i18n";

import parse from "html-react-parser";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";

function WalletAdvantagesNoItem() {
  const p = useP();

  const [subscribed, setSubscribed] = useState(false);

  const { loggedInUser } = useContext(authContext);

  const [subscribeToNewsletter] = useMutation(SUBSCRIBE_TO_NEWSLETTER);

  const onClickSubscribeNewsletter = async () => {
    await subscribeToNewsletter();

    setSubscribed(true);
  };

  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-md">
      <Disclosure as="div" className=" px-6 py-6">
        {({ open }) => (
          <>
            <dt>
              <Disclosure.Button className="flex w-full items-center justify-between text-left text-slate-900">
                <span className="text-base font-semibold">
                  {p.t("accountPage.advantages.noItems")}
                </span>
                <span className="ml-6 flex items-center">
                  {open ? (
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                  ) : (
                    <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                  )}
                </span>
              </Disclosure.Button>
            </dt>
            <Disclosure.Panel as="dd" className="pt-6 md:pr-12 space-y-6">
              <div className="space-y-6 text-base text-slate-500">
                {parse(p.t("accountPage.advantages.noItemsDescription"))}
              </div>
              <div className="space-y-4 text-base text-slate-500">
                {!loggedInUser.newsletter
                  ? parse(
                      p.t("accountPage.advantages.noItemsDescriptionNewsletter")
                    )
                  : parse(
                      p.t("accountPage.advantages.noItemsDescriptionSeeYouSoon")
                    )}
              </div>
              {!loggedInUser.newsletter && (
                <div className="w-fit">
                  <button
                    type="button"
                    className="inline-flex items-center text-left rounded-md border border-transparent bg-slate-900 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-slate-800 focus:outline-none focus:ring-2 focus:ring-slate-700 focus:ring-offset-2"
                    onClick={onClickSubscribeNewsletter}
                  >
                    {subscribed ? (
                      <div className="flex flex-row md:flex-row items-center space-x-1 ">
                        <span>{p.t("commons.inscriptionConfirmed")}</span>
                        <span className="hidden md:flex">
                          <img
                            src="/images/icons/party-popper_app.png"
                            className="w-4"
                          />
                        </span>
                      </div>
                    ) : (
                      p.t("accountPage.advantages.joinNewsletter")
                    )}
                  </button>
                </div>
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
}

export default WalletAdvantagesNoItem;
