import { gql } from "@apollo/client";

const TOKEN_CLAIM_VALIDATION = gql`
  query TokenClaimValidation($token: String!) {
    tokenClaimValidation(token: $token) {
      success
      error
      collection {
        code
        label
        network {
          code
          label
        }
        owner {
          displayedName
        }
      }
    }
  }
`;

const CLAIM = gql`
  mutation Claim($claim: ClaimInput!) {
    claim(claim: $claim) {
      message
      success
    }
  }
`;

export { TOKEN_CLAIM_VALIDATION, CLAIM };
