import React, { useState } from "react";

import PropTypes from "prop-types";
import RegisterFormModal from "./RegisterFormModal";
import LoginFormModal from "./LoginFormModal";
import Modal from "../../../components/Modal";
import { useP } from "../../../services/i18n";
import { useContext } from "react";
import { authContext } from "../../../AuthContext";
import { useEffect } from "react";
import RenewPasswordFormModal from "./RenewPasswordFormModal";

function AuthModal({ open, onClose }) {
  const p = useP();

  const { authModaleStatus, setAuthModaleStatus } = useContext(authContext);
  const [status, setStatus] = useState(authModaleStatus);

  const toggleMode = () => {
    authModaleStatus === "signIn"
      ? setAuthModaleStatus("register")
      : setAuthModaleStatus("signIn");
  };

  const goBackToLogin = () => {
    setAuthModaleStatus("signIn");
  };

  const goToRenewPassword = () => {
    setAuthModaleStatus("renewPassword");
  };

  /** Tricks to avoid transition conflicts */
  useEffect(() => {
    if (authModaleStatus !== "hidden") {
      setStatus(authModaleStatus);
    }
  }, [authModaleStatus]);

  const getAuthModaleBody = () => {
    let res;

    switch (status) {
      case "renewPassword":
        res = (
          <RenewPasswordFormModal
            goBackToLogin={goBackToLogin}
            open={open}
            onClose={onClose}
          />
        );
        break;

      case "register":
        res = (
          <RegisterFormModal
            toggleMode={toggleMode}
            open={open}
            onClose={onClose}
          />
        );
        break;

      default:
        res = (
          <LoginFormModal
            toggleMode={toggleMode}
            open={open}
            onClose={onClose}
            goToRenewPassword={goToRenewPassword}
          />
        );
        break;
    }

    return res;
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        title={
          status !== "hidden"
            ? p.t(`navigation.authModal.${status}.modalTitle`)
            : ""
        }
        className="md:max-w-xl"
      >
        <div className="px-5 pt-2 pb-10">{getAuthModaleBody()}</div>
      </Modal>
    </>
  );
}

AuthModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default AuthModal;
