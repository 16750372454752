import { Disclosure } from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";

const faqs = [
  {
    question: "Qu'est ce que la blockchain ?",
    answer: `La blockchain est une technologie qui permet de stocker et de transmettre des informations en toute sécurité sur internet. Au lieu d'avoir une seule base de données centrale, la blockchain est décentralisée, ce qui signifie que les informations sont stockées sur de nombreux ordinateurs différents. Chaque ordinateur vérifie que les informations sont correctes avant de les enregistrer dans un "bloc". Ces blocs sont ensuite reliés les uns aux autres, formant ainsi une "chaîne de blocs" ou "blockchain". Cette technologie est très sécurisée car chaque bloc est protégé par des codes complexes et ne peut pas être modifié une fois qu'il a été ajouté à la chaîne. La blockchain est utilisée pour stocker des informations importantes, comme les NFT`,
  },
  {
    question: "Les NFT ? Comment ça marche ?",
    answer: `Les NFT, ou "jetons non fongibles", sont des jetons numériques uniques qui permettent de représenter des objets de valeur comme des œuvres d'art, des vidéos, des chansons et d'autres biens virtuels. Contrairement aux jetons traditionnels comme les crypto-monnaies, les NFT sont uniques et ne peuvent pas être échangés contre d'autres NFT à un taux de change fixe. Chaque NFT est unique et possède sa propre valeur, qui est déterminée par l'offre et la demande du marché. Les NFT sont stockés sur la blockchain, une technologie qui permet de stocker des informations de manière sécurisée et décentralisée. L'utilisation de NFT permet de garantir l'authenticité et la propriété des objets numériques, ce qui peut avoir des implications importantes dans des domaines tels que l'art, le divertissement et le commerce électronique.`,
  },
  {
    question: "Suis-je propriétaire de mon portefeuille ?",
    answer:
      "Oui, grâce à Fragment Connect, vous êtes le seul et unique propriétaire de votre portefeuille. Ce portefeuille est créé spécifiquement pour vous et vous êtes en mesure de réclamer votre clé privée à tout moment, ce qui signifie que vous avez un contrôle total sur vos données et sur tout ce qui est stocké dans votre portefeuille.",
  },
];

export default function FAQ() {
  return (
    <div className=" bg-gray-100">
      <div className="mx-auto max-w-7xl px-6 py-12 sm:py-18 lg:py-18 lg:px-8 ">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <div>
            <h2 className="w-fit text-2xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
              Nous répondons
            </h2>
            <p className="mt-1 text-2xl font-bold tracking-tight text-slate-900">
              à toutes vos questions
            </p>
          </div>

          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">
                          {faq.question}
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          ) : (
                            <PlusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600 text-justify">
                        {faq.answer}
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
