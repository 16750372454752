import React from "react";
import Footer from "../Footer/Footer";
import Advantages from "./Advantages/Advantages";
import Attributes from "./Attributes/Attributes";
import Banner from "./Banner/Banner";
import Blockchain from "./Blockchain/Blockchain";
import FAQ from "./FAQ/FAQ";
import NFT from "./NFT/NFT";

function HomePage() {
  return (
    <div className="flex flex-col flex-1">
      <Banner />
      <NFT />
      <Attributes />
      <Advantages />
      <Blockchain />
      <FAQ />

      <div className="py-12"></div>

      <Footer />
    </div>
  );
}

export default HomePage;
