import React from "react";
import { useP } from "../../services/i18n";
import PropTypes from "prop-types";

function ClaimPageTitle({ collection }) {
  const p = useP();
  return (
    <div className="text-center space-y-2">
      <h2 className="text-lg font-semibold text-transparent bg-clip-text bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
        {collection
          ? collection.owner.displayedName
          : p.t("claimPage.formTitle.secondaryTitle")}
      </h2>
      <p className="text-4xl font-bold tracking-tight text-gray-900">
        {collection ? collection.label : p.t("claimPage.formTitle.title")}
      </p>
      <p className="text-4xl font-bold tracking-tight text-gray-900 flex justify-center">
        <img src="/images/icons/party-popper_app.png" className="w-12" />
      </p>
    </div>
  );
}

ClaimPageTitle.propTypes = {
  collection: PropTypes.object,
};

export default ClaimPageTitle;
