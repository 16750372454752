import React, { useState } from "react";
import { useP } from "../../../services/i18n";
import PropTypes from "prop-types";

import WalletAdvantagesItems from "./WalletAdvantagesItems";
import WalletAdvantagesNoItem from "./WalletAdvantageNoItem";
import WalletAdvantageModal from "./WalletAdvantageModal/WalletAdvantageModal";
import Spinner from "../../../components/Spinner";

function WalletAdvantages({ walletData, loading, getWalletData }) {
  const p = useP();

  const [selectedAdvantage, setSelectedAdvantage] = useState(null);

  const [advantageModalOpen, setAdvantageModalOpen] = useState(false);

  const onCloseAdvantageModal = () => {
    setAdvantageModalOpen(false);
  };

  const onSelectAdvantage = (advantage) => {
    setSelectedAdvantage(advantage);
    setAdvantageModalOpen(true);
  };

  return (
    <>
      <div className="flex flex-1 flex-col space-y-6 md:space-y-12 px-4 sm:px-6 lg:px-0">
        <h2 className="text-2xl font-bold tracking-tight text-slate-900 ">
          {p.t("accountPage.advantages.title")}
        </h2>
        <div>
          {loading ? (
            <Spinner />
          ) : walletData?.advantages.length ? (
            <WalletAdvantagesItems
              advantages={walletData?.advantages}
              onSelectAdvantage={onSelectAdvantage}
            />
          ) : (
            <WalletAdvantagesNoItem />
          )}
        </div>
      </div>
      <WalletAdvantageModal
        open={advantageModalOpen}
        onClose={onCloseAdvantageModal}
        advantage={selectedAdvantage ? selectedAdvantage : {}}
        getWalletData={getWalletData}
      />
    </>
  );
}

WalletAdvantages.propTypes = {
  loading: PropTypes.bool,
  walletData: PropTypes.object,
  getWalletData: PropTypes.func,
};

export default WalletAdvantages;
