import React from "react";
import { Outlet } from "react-router-dom";
import Navigation from "./pages/Navigation/Navigation";

function MainContainer() {
  return (
    <div className="h-screen w-screen flex flex-col bg-white">
      <Navigation />
      <Outlet />
    </div>
  );
}

export default MainContainer;
