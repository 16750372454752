import React from "react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import PropTypes from "prop-types";
// import { useP } from "../../../services/i18n";

function AttributeItem({ label }) {
  return (
    <div className="flex flex-col border-2 border-slate-200 bg-slate-50 rounded-lg shadow-sm px-4 py-4 space-y-2 items-center">
      <div>
        <CheckCircleIcon className="w-7 h-7 text-green-500" />
      </div>
      <div className="flex flex-1 text-center items-center">{label}</div>
    </div>
  );
}

AttributeItem.propTypes = {
  label: PropTypes.string,
};

function Attributes() {
  // const p = useP();

  return (
    <div className="w-full flex flex-col justify-center items-center ">
      <div className="flex flex-col max-w-7xl py-10 px-4 md:py-12 sm:px-6 lg:px-8 space-y-8">
        <div className="flex justify-center items-end">
          <img
            src="/images/landing/stars_levels_without_shadow.webp"
            alt=""
            className="w-80 md:w-96 floating-element"
          />
        </div>
        <div className=" flex flex-col justify-center items-center">
          <h2 className="w-fit text-2xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
            Faites évoluer
          </h2>
          <p className="mt-1 text-2xl font-bold tracking-tight text-slate-900 text-center md:text-left">
            Votre collection et débloquez de&nbsp;nouveaux attributs
          </p>
          <div className="mt-8 md:mt-12 text-lg text-slate-500">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-x-8 gap-y-4">
              <AttributeItem label="Vous avez su être là au bon moment" />

              <AttributeItem label="Vous avez été actif" />

              <AttributeItem label="Vous avez été sélectionné" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Attributes;
