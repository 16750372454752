import React from "react";

import PropTypes from "prop-types";
import { useP } from "../../../services/i18n";
import {
  CalendarIcon,
  DocumentTextIcon,
  TagIcon,
} from "@heroicons/react/24/outline";
import { getMinifiedKey } from "../../../utils/utils";

function CollectionItem({ collection, onSelectCollection }) {
  const p = useP();

  return (
    <li key={collection.id} onClick={() => onSelectCollection(collection)}>
      <a href="#" className="block hover:bg-slate-50">
        <div className="px-4 py-4 sm:px-6">
          <div className="flex items-center justify-between">
            <p className="truncate text-sm font-medium text-blue-600">
              {collection.label}
            </p>
            <div className="ml-2 flex flex-shrink-0 space-x-2">
              <p className="inline-flex rounded-full bg-gray-100 px-2 text-xs font-semibold leading-5 text-gray-800">
                {collection.revealed
                  ? p.t("accountPage.owner.collections.revealed")
                  : p.t("accountPage.owner.collections.notRevealed")}
              </p>
              <p className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                {collection.network.label}
              </p>
            </div>
          </div>
          <div className="mt-2 sm:flex sm:justify-between">
            <div className="sm:flex">
              <p className="flex items-center text-sm text-slate-500">
                <TagIcon
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-slate-400"
                  aria-hidden="true"
                />
                {p.t("accountPage.owner.collections.total")}{" "}
                {collection.totalSupply}
              </p>
              <p className="mt-2 flex items-center text-sm text-slate-500 sm:mt-0 sm:ml-6 hover:text-slate-600">
                <DocumentTextIcon
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-slate-400"
                  aria-hidden="true"
                />
                {getMinifiedKey(collection.contractAddress, 5)}
              </p>
            </div>
            <div className="mt-2 flex items-center text-sm text-slate-500 sm:mt-0">
              <CalendarIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-slate-400"
                aria-hidden="true"
              />
              <p>
                {p.t("accountPage.owner.collections.creationDate")}{" "}
                <time dateTime={collection.createdAt}>
                  {new Date(collection.createdAt).toLocaleDateString()}
                </time>
              </p>
            </div>
          </div>
        </div>
      </a>
    </li>
  );
}

CollectionItem.propTypes = {
  collection: PropTypes.object,
  onSelectCollection: PropTypes.func,
};

export default CollectionItem;
