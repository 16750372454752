import { gql } from "@apollo/client";

const AUTHENTIFY = gql`
  mutation Authentify($auth: AuthInput!) {
    authentify(auth: $auth) {
      success
      message
      error
      token
    }
  }
`;

const REGISTER = gql`
  mutation Register($register: RegisterInput!) {
    register(register: $register) {
      success
      message
      error
      token
    }
  }
`;

const SEND_RENEW_PASSWORD_REQUEST = gql`
  mutation SendRenewPasswordRequest($email: String!) {
    sendRenewPasswordRequest(email: $email) {
      error
      message
      success
    }
  }
`;

export { AUTHENTIFY, REGISTER, SEND_RENEW_PASSWORD_REQUEST };
