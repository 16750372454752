import React, { useContext } from "react";
import { authContext } from "../../../AuthContext";
// import { useP } from "../../../services/i18n";

function Banner() {
  // const p = useP();

  const { loggedInUser, setAuthModaleStatus } = useContext(authContext);

  const onConnect = () => {
    setAuthModaleStatus("signIn");
  };

  return (
    <div className="w-full h-3/4 flex flex-col justify-center items-center">
      <div className="mx-auto max-w-7xl py-10 px-4 md:py-12 sm:px-6 lg:px-8 space-y-8 md:space-y-10 flex flex-col items-center">
        <div className="">
          <img
            src="/images/landing/astronaut_flying.webp"
            alt=""
            className="w-72 md:w-80 floating-element"
          />
        </div>
        <div className="flex flex-col items-center text-center">
          <h2 className="w-fit text-2xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
            Catapult by Fragment
          </h2>
          <p className="mt-1 text-4xl font-bold tracking-tight text-slate-900 sm:text-5xl lg:text-6xl">
            La fidélité client du futur
          </p>
          <p className="mx-auto mt-5 max-w-xl text-xl text-slate-500">
            N&apos;avez vous jamais rêvé de recevoir des avantages à la hauteur
            de votre fidélité client ?
          </p>
        </div>

        <div className="pb-8 md:pb-12">
          {!loggedInUser && (
            <button
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-slate-900 px-12 py-3 text-md font-medium text-white shadow-lg hover:bg-slate-800 focus:outline-none focus:ring-2 focus:ring-slate-700 focus:ring-offset-2 cursor-pointer"
              type="button"
              onClick={onConnect}
            >
              Rejoindre l&apos;aventure dès&nbsp;maintenant
            </button>
          )}
        </div>

        {/* <div className="flex flex-col items-center pb-8 md:pb-12 space-y-1 animate-pulse">
          <p className="mx-auto mt-5 max-w-xl text-md text-slate-500">
            Découvrir le concept
          </p>
          <ChevronDoubleDownIcon className="text-slate-500 w-5 h-5" />
        </div> */}
      </div>
    </div>
  );
}

export default Banner;
