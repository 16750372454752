import { gql } from "@apollo/client";

const GET_OWNER_DATA = gql`
  query OwnerData {
    ownerData {
      code
      contractAddress
      label
      network {
        label
        code
      }
      owner {
        displayedName
      }
      revealed
      createdAt
      totalSupply
    }
  }
`;

const GET_CLAIM_CODES = gql`
  query ClaimCodes($code: String!) {
    claimCodes(code: $code) {
      sendTo
      dateBurn
      global
      receivers {
        email
      }
      status {
        code
        label
      }
      tags {
        id
        label
      }
      token
    }
  }
`;

const GET_WALLET_DATA = gql`
  query WalletData {
    walletData {
      creators {
        collections {
          code
          label
          revealed
          contractAddress
          items {
            tokenId
            tokenType
            title
            description
            externalUrl
            media {
              thumbnail
              raw
              gateway
            }

            rawMetadata
          }
          network {
            code
            label
          }
        }

        creator
      }

      advantages {
        title
        description
        expirationDate
        type {
          code
        }
        collection {
          owner {
            displayedName
          }
        }
        advantageAttributeId
        nftId
        statusCode
        content
      }
    }
  }
`;

const CREATE_CLAIM_CODE = gql`
  mutation CreateClaimCode($code: String!, $global: Boolean) {
    createClaimCode(code: $code, global: $global) {
      success
      message
      error
    }
  }
`;

const CREATE_CLAIM_CODES = gql`
  mutation CreateClaimCodes($code: String!, $datas: [NewClaimCodeInput]!) {
    createClaimCodes(code: $code, datas: $datas) {
      success
      message
      error
    }
  }
`;

const SAVE_CLAIM_CODE = gql`
  mutation SaveClaimCode($params: ClaimCodeInput!) {
    saveClaimCode(params: $params) {
      error
      message
      success
    }
  }
`;

const DELETE_CLAIM_CODE = gql`
  mutation DeleteClaimCode($token: String!) {
    deleteClaimCode(token: $token) {
      error
      message
      success
    }
  }
`;

const SEND_CLAIM_CODE = gql`
  mutation SendClaimCodes($tokens: [String]!) {
    sendClaimCodes(tokens: $tokens) {
      error
      message
      success
    }
  }
`;

const GET_TAGS = gql`
  query Tags($collectionCode: String!) {
    tags(collectionCode: $collectionCode) {
      id
      label
    }
  }
`;

const ADD_CLAIM_CODE_TAG = gql`
  mutation AddClaimCodeTag($claimCode: String!, $tag: TagInput) {
    addClaimCodeTag(claimCode: $claimCode, tag: $tag) {
      error
      message
      success
    }
  }
`;

const REMOVE_CLAIM_CODE_TAG = gql`
  mutation RemoveClaimCodeTag($claimCode: String!, $tagId: ID!) {
    removeClaimCodeTag(claimCode: $claimCode, tagId: $tagId) {
      error
      message
      success
    }
  }
`;

const USE_ADVANTAGE = gql`
  mutation UseAdvantage($input: UseAdvantageInput) {
    useAdvantage(input: $input) {
      error
      message
      success
      content
    }
  }
`;

export {
  GET_WALLET_DATA,
  GET_OWNER_DATA,
  GET_CLAIM_CODES,
  CREATE_CLAIM_CODE,
  CREATE_CLAIM_CODES,
  SAVE_CLAIM_CODE,
  DELETE_CLAIM_CODE,
  SEND_CLAIM_CODE,
  GET_TAGS,
  ADD_CLAIM_CODE_TAG,
  REMOVE_CLAIM_CODE_TAG,
  USE_ADVANTAGE,
};
