import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useP } from "../../../../../services/i18n";

import csvtojson from "csvtojson";
import { useEffect } from "react";
import Spinner from "../../../../../components/Spinner";

import PropTypes from "prop-types";

export default function ImportClaimCodeModal({ open, onClose, onClickImport }) {
  const p = useP();

  const [currentState, setCurrentState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [waitingConfirmation, setWaitingConfirmation] = useState(false);
  const [csvData, setCsvData] = useState(null);

  const ANALYSE_STEP = "ANALYSE";
  const IMPORT_PREPARE_STEP = "IMPORT_PREPARE";
  const ERROR_STEP = "ERROR";
  const WAITING_CONFIRM_STEP = "WAITING_CONFIRM";
  const IMPORT_STEP = "IMPORT";

  const handleFileSelect = (event) => {
    setLoading(true);

    const file = event.target.files[0];

    const reader = new FileReader();

    setCurrentState(ANALYSE_STEP);

    reader.onload = async (event) => {
      const csvString = event.target.result;

      if (validateCsv(csvString)) {
        const jsonData = await convertCsvToJson(csvString);
        setCsvData(jsonData);

        setCurrentState(WAITING_CONFIRM_STEP);
        setWaitingConfirmation(true);
        setLoading(false);
      } else {
        setCurrentState(ERROR_STEP);
      }
    };

    reader.readAsText(file);
    setCurrentState(IMPORT_PREPARE_STEP);
  };

  const validateCsv = (csvString) => {
    const regex =
      /^([\w.%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,})\s*;\s*([^\n]+)\s*\n?/;
    return regex.test(csvString);
  };

  const convertCsvToJson = async (csvString) => {
    const jsonArray = await csvtojson({
      noheader: true,
      delimiter: ";",
    }).fromString(csvString);
    return jsonArray.map((row) => {
      const tags = row.field2
        ? row.field2.split("|").map((tag) => tag.trim())
        : [];
      return {
        email: row.field1,
        tags: tags.map((tag) => ({
          label: tag,
        })),
      };
    });
  };

  const onConfirmImport = async () => {
    await onClickImport(csvData);
  };

  const getCurrentState = () => {
    let label;

    switch (currentState) {
      case IMPORT_PREPARE_STEP:
        label = p.t(
          "accountPage.owner.collectionDetails.importActivationCodes.importPrepare"
        );
        break;

      case IMPORT_STEP:
        label = p.t(
          "accountPage.owner.collectionDetails.importActivationCodes.import"
        );
        break;

      case ERROR_STEP:
        label = p.t(
          "accountPage.owner.collectionDetails.importActivationCodes.incorrectCsv"
        );
        break;

      case WAITING_CONFIRM_STEP:
        label = p.t(
          "accountPage.owner.collectionDetails.importActivationCodes.confirmImport",
          {
            count: csvData.length ? csvData.length : 0,
          }
        );
        break;

      case ANALYSE_STEP:
        label = p.t(
          "accountPage.owner.collectionDetails.importActivationCodes.analyse"
        );
        break;

      default: {
        label = "";
      }
    }

    return <div className="text-sm mt-8 font-bold">{label}</div>;
  };

  const getButton = () => {
    if (loading) {
      return (
        <label
          id="import-csv"
          type="button"
          className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm cursor-pointer"
        >
          <Spinner color="white" size="h-4 w-4" borderWidth="sm" />
        </label>
      );
    }

    if (waitingConfirmation) {
      return (
        <label
          id="import-csv"
          type="button"
          className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm cursor-pointer"
          onClick={onConfirmImport}
        >
          {p.t(
            "accountPage.owner.collectionDetails.importActivationCodes.confirmImportButton"
          )}
        </label>
      );
    }

    return (
      <label
        id="import-csv"
        htmlFor="file-input"
        type="button"
        className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm cursor-pointer"
      >
        {p.t(
          "accountPage.owner.collectionDetails.importActivationCodes.importButton"
        )}
      </label>
    );
  };

  useEffect(() => {
    if (!open) {
      setCurrentState(false);
      setLoading(false);
      setWaitingConfirmation(false);
      setCsvData(null);
    }
  }, [open]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start pb-4">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon
                      className="h-6 w-6 text-blue-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      {p.t(
                        "accountPage.owner.collectionDetails.importActivationCodes.title"
                      )}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {p.t(
                          "accountPage.owner.collectionDetails.importActivationCodes.description"
                        )}
                      </p>
                    </div>

                    <div className="mt-2 text-sm text-gray-500 font-bold">
                      <p className="">
                        {p.t(
                          "accountPage.owner.collectionDetails.importActivationCodes.format"
                        )}
                      </p>
                      <ul>
                        <li>
                          -{" "}
                          {p.t(
                            "accountPage.owner.collectionDetails.importActivationCodes.format1"
                          )}
                        </li>
                        <li>
                          -{" "}
                          {p.t(
                            "accountPage.owner.collectionDetails.importActivationCodes.format2"
                          )}
                        </li>
                        <li>
                          -{" "}
                          {p.t(
                            "accountPage.owner.collectionDetails.importActivationCodes.format3"
                          )}
                        </li>
                      </ul>
                    </div>
                    {getCurrentState()}
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  {getButton()}
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={onClose}
                  >
                    {p.t("commons.cancel")}
                  </button>
                </div>
                {open && (
                  <input
                    id="file-input"
                    className="hidden"
                    type="file"
                    onChange={handleFileSelect}
                    accept=".csv"
                  />
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

ImportClaimCodeModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onClickImport: PropTypes.func,
};
