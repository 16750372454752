import { createContext } from "react";

export const AUTH_DEFAULT_VALUE = {
  authModaleStatus: "hidden",
  setAuthModaleStatus: null,

  loggedInUser: null,
  setLoggedInUser: null,

  token: null,
  setToken: null,
};

export const authContext = createContext(AUTH_DEFAULT_VALUE);
