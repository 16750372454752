/* eslint-disable react/prop-types */
import React from "react";

function Spinner({ color = "primary", size = "h-5 w-5", borderWidth = "2" }) {
  let borderColor = "";
  switch (color) {
    case "primary":
      borderColor = "border-primary-600";
      break;
    case "secondary":
      borderColor = "border-secondary-600";
      break;
    case "white":
      borderColor = "border-white";
      break;
    case "gray":
      borderColor = "border-gray-500";
      break;
    case "slate":
      borderColor = "border-slate-300";
      break;
    default:
      borderColor = "border-gray-900";
      break;
  }

  let borderSize = "";
  switch (borderWidth) {
    case "xs":
      borderSize = "border";
      break;
    case "sm":
      borderSize = "border-2";
      break;
    case "md":
      borderSize = "border-4";
      break;
    case "lg":
      borderSize = "border-8";
      break;
    default:
      borderSize = "border-2";
      break;
  }

  return (
    <div
      className={`${borderColor} border-r-transparent animate-spin inline-block ${size} ${borderSize} rounded-full `}
      role="status"
    ></div>
  );
}

export default Spinner;
