import React from "react";

import PropTypes from "prop-types";
import { XCircleIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";

function Tag({ tag, color = "slate", onRemove }) {
  return (
    <span
      className={`inline-flex items-center rounded-full bg-${color}-100 pl-2.5 pr-1 py-0.5 text-sm font-medium text-${color}-800 w-fit`}
    >
      <div className="mr-1 truncate cursor-default" title={tag.label}>
        {tag.label}
      </div>

      <div onClick={onRemove} className="cursor-pointer">
        <XCircleIcon
          className={clsx(
            "w-5 h-5",
            `text-${color}-300 hover:text-${color}-500`
          )}
        />
      </div>
    </span>
  );
}

Tag.propTypes = {
  tag: PropTypes.object,
  color: PropTypes.string,
  onRemove: PropTypes.func,
};

export default Tag;
