import React from "react";

import PropTypes from "prop-types";

function Input({
  name,
  label,
  type = "text",
  required,
  value,
  setValue,
  disabled = false,
  autoComplete,
  onKeyPress = () => null,
}) {
  return (
    <div>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="mt-2">
        <input
          type={type}
          name={name}
          autoComplete={autoComplete}
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-slate-500 focus:ring-slate-500 sm:text-sm"
          required={required}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          disabled={disabled}
          onKeyPress={onKeyPress}
        />
      </div>
    </div>
  );
}

Input.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
  autoComplete: PropTypes.string,
  setValue: PropTypes.func,
  disabled: PropTypes.bool,
  onKeyPress: PropTypes.func,
};

export default Input;
