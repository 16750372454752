import React, { useState } from "react";

import PropTypes from "prop-types";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import WalletCollectionItems from "./WalletCollectionItems";

function WalletCollection({ creator, onSelectItem }) {
  const [toggled, setToggled] = useState(false);

  const toggle = () => {
    setToggled(!toggled);
  };

  const totalCount = () => {
    let count = 0;

    creator.collections.forEach((collection) => {
      count += collection.items.length;
    });

    return count;
  };

  return (
    <div className="">
      <div className="">
        <div className="flex items-center justify-between">
          <div className="flex flex-row  space-x-4 items-center">
            <div className="flex flex-row space-x-4 space-y-0 items-center ">
              <div
                onClick={toggle}
                className="flex flex-row items-center space-x-2 cursor-pointer"
              >
                <div>
                  {toggled ? (
                    <ChevronRightIcon className="w-5 h-5" />
                  ) : (
                    <ChevronDownIcon className="w-5 h-5" />
                  )}
                </div>
                <h2 className=" text-xl font-semibold tracking-tight text-slate-900 ">
                  {creator.creator}
                </h2>
              </div>
              <div className="flex flex-row space-x-2 items-center justify-end">
                <div className="w-fit rounded-full bg-slate-100 border border-slate-200 px-4 py-0.5 cursor-default">
                  {totalCount()}
                </div>
              </div>
            </div>
          </div>

          {/* <a
            href="#"
            className="hidden text-sm font-semibold text-transparent bg-clip-text bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 hover:from-indigo-600 hover:via-purple-600 hover:to-pink-600 sm:block"
          >
            {p.t("accountPage.wallet.showCollection")}
            <span aria-hidden="true"> &rarr;</span>
          </a> */}
        </div>
        {!toggled && (
          <div className="relative mt-8">
            <div className="relative -mb-6 w-full overflow-x-auto pb-6">
              <ul
                role="list"
                className="mx-4 inline-flex space-x-8 gap-y-8 sm:mx-6 lg:mx-0 lg:grid lg:grid-cols-4 lg:gap-x-8 lg:space-x-0"
              >
                {creator.collections.map((collection, index) => (
                  <WalletCollectionItems
                    key={index}
                    collection={collection}
                    onSelectItem={onSelectItem}
                  />
                ))}
              </ul>
            </div>
          </div>
        )}

        {/* <div className="mt-12 flex sm:hidden">
          <a
            href="#"
            className="text-sm font-semibold text-transparent bg-clip-text bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 hover:from-indigo-600 hover:via-purple-600 hover:to-pink-600 sm:block"
          >
            {p.t("accountPage.wallet.showCollection")}
            <span aria-hidden="true"> &rarr;</span>
          </a>
        </div> */}
      </div>
    </div>
  );
}

WalletCollection.propTypes = {
  creator: PropTypes.object,
  onSelectItem: PropTypes.func,
};

export default WalletCollection;
