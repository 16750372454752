import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

import PropTypes from "prop-types";
import { getAdvantageDatas } from "../../../../utils/utils";
import clsx from "clsx";
import {
  ADVANTAGE_TYPE_CATAPULT_INVITE,
  ADVANTAGE_TYPE_GIFT_CARD,
} from "../../../../utils/constants";
import AdvantageCatapultInviteBody from "./AdvantageCatapultInviteBody";
import AdvantageGiftCodeBody from "./AdvantageGiftCodeBody";

export default function WalletAdvantageModal({
  advantage,
  open,
  onClose,
  getWalletData,
}) {
  const getIcon = () => {
    const Icon = getAdvantageDatas(advantage).icon;

    return <Icon className={`h-6 w-6 text-slate-900`} aria-hidden="true" />;
  };

  const getSecondaryColor = () => {
    return getAdvantageDatas(advantage).secondaryColor;
  };

  const getAdvantageBody = () => {
    let res;
    switch (advantage?.type?.code) {
      case ADVANTAGE_TYPE_CATAPULT_INVITE:
        res = (
          <AdvantageCatapultInviteBody
            advantage={advantage}
            onClose={onClose}
            getWalletData={getWalletData}
          />
        );
        break;
      case ADVANTAGE_TYPE_GIFT_CARD:
        res = (
          <AdvantageGiftCodeBody
            advantage={advantage}
            onClose={onClose}
            getWalletData={getWalletData}
          />
        );
        break;
      default:
        res = <></>;
        break;
    }

    return res;
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div
                    className={clsx(
                      "mx-auto flex h-12 w-12 items-center justify-center rounded-full",
                      `bg-${getSecondaryColor()}`
                    )}
                  >
                    {getIcon()}
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      {advantage.title}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {advantage.description}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="pt-8">{getAdvantageBody()}</div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

WalletAdvantageModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  advantage: PropTypes.object,
  getWalletData: PropTypes.func,
};
