import React from "react";
import { StarIcon } from "@heroicons/react/24/solid";
// import { useP } from "../../../services/i18n";

function NFT() {
  // const p = useP();

  return (
    <div className="w-full flex flex-col justify-center items-center bg-gray-100">
      <div className="flex flex-col-reverse md:flex-row max-w-7xl px-4 sm:px-6 lg:px-8 ">
        <div className="flex justify-center items-end">
          <img
            src="/images/landing/collect_phone.webp"
            alt=""
            className="w-80 md:w-96"
          />
        </div>
        <div className="py-8 px-4 md:py-10 sm:px-6 lg:px-8 flex flex-col justify-center">
          <h2 className="w-fit text-2xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
            Collectionnez
          </h2>
          <p className="mt-1 text-2xl font-bold tracking-tight text-slate-900">
            Vos objets de marque uniques et certifiés sur la blockchain
            simplement
          </p>
          <div className="mt-8 text-xl text-slate-500">
            <ul className="space-y-4">
              <li className="flex">
                <div>
                  <StarIcon className="w-7 h-7 mr-4 text-indigo-500" />
                </div>
                <div>Achats physiques ou en ligne</div>
              </li>
              <li className="flex">
                <div>
                  <StarIcon className="w-7 h-7 mr-4 text-indigo-500" />
                </div>
                <div>Participation à des évènements de marque</div>
              </li>
              <li className="flex">
                <div>
                  <StarIcon className="w-7 h-7 mr-4 text-indigo-500" />
                </div>
                <div>Offert par un proche</div>
              </li>
            </ul>
          </div>
          <p className="mt-8 text-xl text-slate-500">
            Toutes les occasions sont bonnes pour commencer à collectionner !
          </p>
        </div>
      </div>
    </div>
  );
}

export default NFT;
