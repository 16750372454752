import { SparklesIcon } from "@heroicons/react/24/solid";
import React from "react";
// import { useP } from "../../../services/i18n";

function Advantages() {
  // const p = useP();

  return (
    <div className="w-full flex flex-col justify-center items-center bg-gray-100">
      <div className="flex flex-col-reverse md:flex-row max-w-7xl py-8 px-4 md:py-10 sm:px-6 lg:px-8">
        <div className="md:w-2/3 flex flex-col justify-center">
          <h2 className="w-fit text-2xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
            Obtenez
          </h2>
          <p className="mt-1 text-2xl font-bold tracking-tight text-slate-900">
            Des avantages uniques à la hauteur de votre fidélité
          </p>
          <div className="mt-8 text-xl text-slate-500">
            <ul className="space-y-4">
              <li className="flex">
                <SparklesIcon className="w-7 h-7 mr-4 text-amber-500" />
                Bons d&apos;achats
              </li>
              <li className="flex">
                <SparklesIcon className="w-7 h-7 mr-4 text-amber-500" />
                Invitation à des évènements privés
              </li>
              <li className="flex">
                <SparklesIcon className="w-7 h-7 mr-4 text-amber-500" />
                Ventes en avant-première
              </li>
              <li className="flex">
                <SparklesIcon className="w-7 h-7 mr-4 text-amber-500" />
                Éphémère, long terme ou à usage unique
              </li>
            </ul>
          </div>
          <p className="mt-8 text-xl text-slate-500">
            Chaque attribut peut porter ses avantages exclusifs ! Les créateurs
            sont libres de remercier leur communauté comme ils le souhaitent.
            N&apos;importe comment, n&apos;importe quand.
          </p>
        </div>
        <div className="md:w-1/3 flex justify-center items-center">
          <img
            src="/images/landing/golden_gift.webp"
            alt=""
            className="w-80 md:w-96 ml-10"
          />
        </div>
      </div>
    </div>
  );
}

export default Advantages;
