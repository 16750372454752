import { createContext } from "react";

const defaultProps = {
  locale: "fr",
  setLocale: () => null,
};

const I18nContext = createContext(defaultProps);

export default I18nContext;
