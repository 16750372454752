import React, { useContext, useState } from "react";
import parse from "html-react-parser";
import PropTypes from "prop-types";
import { useP } from "../../../services/i18n";
import Input from "../../../components/Input";
import { REGISTER } from "../queries";
import { useMutation } from "@apollo/client";
import { ClientJS } from "clientjs";
import { authContext } from "../../../AuthContext";
import DynamicButton from "../../../components/DynamicButton";
import {
  FORM_LOADING_STATE,
  FORM_SUCCESS_STATE,
  FORM_WAITING_STATE,
} from "../../../utils/constants";

const client = new ClientJS();

function RegisterFormModal({ toggleMode }) {
  const p = useP();

  const { setToken, setAuthModaleStatus } = useContext(authContext);

  const [register] = useMutation(REGISTER);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newsletter, setNeswletter] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [currentState, setCurrentState] = useState(FORM_WAITING_STATE);

  const onSubmit = async (e) => {
    e.preventDefault();

    setCurrentState(FORM_LOADING_STATE);

    const fingerprint = client.getFingerprint();
    const userAgent = client.getUserAgent();

    const res = await register({
      variables: {
        register: {
          email,
          password,
          newsletter,
          fingerprint: `${fingerprint}`,
          userAgent,
        },
      },
    });

    if (res?.data?.register.success) {
      setCurrentState(FORM_SUCCESS_STATE);
      setToken(res?.data?.register?.token);
      setAuthModaleStatus("hidden");
    } else {
      setCurrentState(FORM_WAITING_STATE);
      setErrorMessage(res?.data?.register?.error);
    }
  };

  return (
    <form className="w-full space-y-8 text-left" onSubmit={onSubmit}>
      <div className="flex flex-col items-center justify-center w-full space-x-3">
        <div>
          <img src="/logo.png" className="w-12 h-w-12" />
        </div>
        <p className="text-xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-slate-800 to-slate-600">
          {p.t("navigation.authModal.title")}
        </p>
      </div>
      <div className="space-y-4">
        <Input
          type="email"
          name="email"
          label={p.t("navigation.authModal.fieldEmail")}
          value={email}
          setValue={setEmail}
          required={true}
        />
        <Input
          type="password"
          name="password"
          label={p.t("navigation.authModal.fieldPassword")}
          value={password}
          setValue={setPassword}
          required={true}
        />
      </div>
      <div className="w-full mb-12 space-y-4">
        <div className="font-medium">
          {p.t("navigation.authModal.register.options.required")}
        </div>
        <div className="relative flex items-start">
          <div className="flex h-5 items-center">
            <input
              id="required-options"
              aria-describedby="required-options-description"
              name="required-options"
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              required={true}
            />
          </div>
          <div className="ml-3 text-sm">
            <span id="required-options-description" className="text-gray-500">
              {parse(
                p.t("navigation.authModal.register.options.termOfServices")
              )}
            </span>
          </div>
        </div>
        <div className="relative flex items-start">
          <div className="flex h-5 items-center">
            <input
              id="optional"
              aria-describedby="optional-description"
              name="optional"
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              required={true}
            />
          </div>
          <div className="ml-3 text-sm">
            <span id="optional-description" className="text-gray-500">
              {p.t("navigation.authModal.register.options.ageCertify")}
            </span>
          </div>
        </div>
      </div>
      <div className="w-full mb-12 space-y-4">
        <div className="font-medium">
          {p.t("navigation.authModal.register.options.optional")}
        </div>
        <div className="relative flex items-start">
          <div className="flex h-5">
            <input
              id="candidates"
              aria-describedby="candidates-description"
              name="candidates"
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              value={newsletter}
              onSelect={() => {
                setNeswletter(!newsletter);
              }}
            />
          </div>
          <div className="ml-3 text-sm">
            <span id="candidates-description" className="text-gray-500">
              {parse(
                p.t("navigation.authModal.register.options.allowCommercial")
              )}
            </span>
          </div>
        </div>
      </div>

      {errorMessage && (
        <div className="text-red-700">{p.t(`errors.${errorMessage}`)}</div>
      )}

      <div className="space-y-4">
        <div className="inline-flex space-x-2 w-full">
          <DynamicButton
            type="submit"
            label={p.t("commons.continue")}
            currentState={currentState}
          />
        </div>
        <div className="w-full mb-12 space-y-4 text-center">
          <p className="text-md italic text-gray-500">
            {p.t("navigation.authModal.register.haveAccountText")}{" "}
            <span
              href="#"
              target="_blank"
              className="cursor-pointer font-semibold italic text-blue-700"
              onClick={toggleMode}
            >
              {p.t("navigation.authModal.register.haveAccountLink")}{" "}
            </span>
          </p>
        </div>
      </div>
    </form>
  );
}

RegisterFormModal.propTypes = {
  toggleMode: PropTypes.func,
};

export default RegisterFormModal;
