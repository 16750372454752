import PropTypes from "prop-types";
import clsx from "clsx";
import { LockClosedIcon } from "@heroicons/react/24/outline";
import { toast } from "react-hot-toast";
import { useP } from "../../../../services/i18n";

export default function CollectionDetailsSidebar({
  navigation,
  selectedTab,
  onSelectTab,
}) {
  const p = useP();

  const onClickItemLocked = () => {
    toast.error(p.t("toasts.itemLocked"));
  };

  return (
    <div className="flex flex-grow flex-col overflow-y-auto border-r border-gray-200 bg-white py-4 pr-2">
      <div className="flex flex-grow flex-col">
        <nav className="flex-1 space-y-1 bg-white" aria-label="Sidebar">
          {navigation?.map((item) => (
            <div
              key={item.label}
              href={item.href}
              className={clsx(
                item.code === selectedTab.code
                  ? "bg-blue-50 border-blue-600 text-blue-600"
                  : item.locked
                  ? "border-transparent  text-gray-300 "
                  : "border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                "group flex items-center px-3 py-2 text-sm font-medium border-l-4 cursor-pointer select-none"
              )}
              onClick={() =>
                !item.locked ? onSelectTab(item) : onClickItemLocked()
              }
            >
              {item.locked ? (
                <LockClosedIcon
                  className={clsx(
                    item.code === selectedTab.code
                      ? "text-blue-500"
                      : "text-gray-400 group-hover:text-gray-500",
                    "mr-3 flex-shrink-0 h-5 w-5"
                  )}
                  aria-hidden="true"
                />
              ) : (
                <item.icon
                  className={clsx(
                    item.code === selectedTab.code
                      ? "text-blue-500"
                      : "text-gray-400 group-hover:text-gray-500",
                    "mr-3 flex-shrink-0 h-5 w-5"
                  )}
                  aria-hidden="true"
                />
              )}

              {item.label}
            </div>
          ))}
        </nav>
      </div>
    </div>
  );
}

CollectionDetailsSidebar.propTypes = {
  navigation: PropTypes.array,
  selectedTab: PropTypes.object,
  onSelectTab: PropTypes.func,
};
