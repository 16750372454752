/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import { useP } from "../../../../services/i18n";

import parse from "html-react-parser";
import Button from "../../../../components/Button";
import { authContext } from "../../../../AuthContext";

function AuthStep() {
  const p = useP();

  const { setAuthModaleStatus } = useContext(authContext);

  const onConnect = () => {
    setAuthModaleStatus("signIn");
  };

  return (
    <div className="flex flex-col w-full h-full space-y-14 justify-between">
      <div className="flex flex-col flex-1 space-y-8 justify-center">
        <div className="w-full">
          <p className="text-md text-gray-500 text-justify">
            {parse(p.t("claimPage.steps.auth.title"))}
          </p>
        </div>
        <div className="w-full">
          <p className="text-md text-gray-500 text-justify">
            {parse(p.t("claimPage.steps.auth.subtitle"))}
          </p>
        </div>
      </div>
      <div className="space-y-4">
        <Button type="button" onClick={onConnect}>
          {parse(p.t("claimPage.steps.auth.connectButton"))}
        </Button>
      </div>
    </div>
  );
}

export default AuthStep;
