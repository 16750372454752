import React from "react";
import PropTypes from "prop-types";
import {
  ArbitrumSvg,
  AvalancheSvg,
  BscSvg,
  EthereumSvg,
  KlaytnSvg,
  OptimismSvg,
  PolygonSvg,
  SolanaSvg,
} from "../../../utils/networks";
// import { useP } from "../../../services/i18n";

function NetworkItem({ children }) {
  return (
    <div className="flex items-center justify-center col-span-2 max-h-12 w-full object-contain lg:col-span-1">
      <div className="w-auto flex border-2 border-slate-200 bg-slate-50 rounded-full shadow-sm px-4 py-4 justify-center items-center">
        {children}
      </div>
    </div>
  );
}

NetworkItem.propTypes = {
  children: PropTypes.any,
};

function Blockchain() {
  // const p = useP();

  return (
    <div className="w-full flex flex-col justify-center items-center ">
      <div className="flex flex-col max-w-7xl py-10 px-4 md:py-12 sm:px-6 lg:px-8 space-y-8">
        <div className="bg-white py-12 sm:py-18">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className=" flex flex-col justify-center items-center">
              <h2 className="w-fit text-2xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
                Une technologie de pointe
              </h2>
              <p className="mt-1 text-2xl font-bold tracking-tight text-slate-900 text-center md:text-left">
                Rendue accessible à tous, tout simplement.
              </p>
            </div>
            <div className="mx-auto mt-10 grid max-w-lg grid-cols-8 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-8 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-4">
              <NetworkItem>
                <EthereumSvg className="w-6 h-6" />
              </NetworkItem>
              <NetworkItem>
                <PolygonSvg className="w-6 h-6" />
              </NetworkItem>
              <NetworkItem>
                <SolanaSvg className="w-6 h-6" />
              </NetworkItem>
              <NetworkItem>
                <ArbitrumSvg className="w-6 h-6" />
              </NetworkItem>
              <NetworkItem>
                <AvalancheSvg className="w-6 h-6" />
              </NetworkItem>
              <NetworkItem>
                <BscSvg className="w-6 h-6" />
              </NetworkItem>
              <NetworkItem>
                <OptimismSvg className="w-6 h-6" />
              </NetworkItem>
              <NetworkItem>
                <KlaytnSvg className="w-6 h-6" />
              </NetworkItem>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blockchain;
