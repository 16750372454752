import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { CheckIcon, PencilIcon, XMarkIcon } from "@heroicons/react/24/outline";

export default function EditableText({
  initialValue,
  emptyValue,
  readOnly = false,
  onSubmit,
}) {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState();

  const toggleEditing = () => {
    setEditing(!editing);
  };

  const onCancel = () => {
    setValue(initialValue);
    setEditing(false);
  };

  const onConfirm = (e) => {
    e.preventDefault();
    setEditing(false);
    onSubmit(value);
  };

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);

  if (readOnly) {
    return <div>{initialValue}</div>;
  }

  return (
    <form
      className="flex flex-row justify-between items-center"
      onSubmit={onConfirm}
    >
      <div>
        {editing ? (
          <input
            type="email"
            autoComplete="off"
            className="block w-full rounded-md border-slate-300 shadow-sm focus:border-slate-500 focus:ring-slate-500 text-sm py-1"
            required={true}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            autoFocus={true}
          />
        ) : value ? (
          value
        ) : (
          emptyValue
        )}
      </div>{" "}
      <div className="flex flex-row space-x-2">
        {editing ? (
          <>
            <div
              className="bg-slate-100 hover:bg-slate-200 rounded-lg p-1.5 cursor-pointer  border-slate-300"
              onClick={onCancel}
            >
              <XMarkIcon className="w-4 h-4 text-red-700 hover:text-red-800" />
            </div>
            <button
              type="submit"
              className="bg-slate-100 hover:bg-slate-200 rounded-lg p-1.5 cursor-pointer  border-slate-300"
            >
              <CheckIcon className="w-4 h-4 text-green-700 hover:text-green-800" />
            </button>
          </>
        ) : (
          <div
            className="hover:bg-slate-100 rounded-lg p-1.5 cursor-pointer"
            onClick={toggleEditing}
          >
            <PencilIcon className="w-4 h-4 text-slate-600 hover:text-blue-600" />
          </div>
        )}
      </div>
    </form>
  );
}

EditableText.propTypes = {
  initialValue: PropTypes.string,
  emptyValue: PropTypes.string,
  readOnly: PropTypes.bool,
  onSubmit: PropTypes.func,
};
