import SecureLS from "secure-ls";

var ls = new SecureLS({ encodingType: "aes" });

const getSecureLs = (key) => {
  return ls.get(key);
};

const setSecureLs = (key, value) => {
  ls.set(key, value);
};

export { getSecureLs, setSecureLs };
