import React from "react";
import ReferalForm from "./ReferalForm";

import ReactHtmlParser from "react-html-parser";
import { useP } from "../../../../../services/i18n";

function ReferralStep() {
  const p = useP();

  const referalAllowed = false;

  return (
    <div>
      <div className="space-y-8">
        <div className="flex flex-row items-center justify-center text-lg font-medium leading-6 space-x-2 text-gray-900">
          <div>{p.t("claimPage.steps.referal.title")}</div>
        </div>
        <div className="space-y-4">
          <div className="text-md text-gray-500">
            {ReactHtmlParser(p.t("claimPage.steps.referal.confirm_1"))}
          </div>
          <div className="text-md text-gray-500">
            {ReactHtmlParser(p.t("claimPage.steps.referal.confirm_2"))}
          </div>
        </div>

        {referalAllowed && (
          <div className="space-y-8">
            <div className="border-b border-slate-200" />
            <ReferalForm />
          </div>
        )}
      </div>
    </div>
  );
}

export default ReferralStep;
