import { useCallback, useState } from "react";
import { getSecureLs, setSecureLs } from "./utils/security";

export const useAuth = () => {
  const [authModaleStatus, setAuthModaleStatus] = useState("hidden");

  const setCurrentAuthModaleStatus = useCallback((currentAuthModaleStatus) => {
    setAuthModaleStatus(currentAuthModaleStatus);
  }, []);

  const [loggedInUser, setLoggedInUser] = useState(getSecureLs("loggedInUser"));

  const setCurrentLoggedInUser = useCallback((currentLoggedInUser) => {
    setSecureLs("loggedInUser", currentLoggedInUser);
    setLoggedInUser(currentLoggedInUser);
  }, []);

  const [token, setToken] = useState(null);

  const setCurrentToken = useCallback((currentToken) => {
    setSecureLs("token", currentToken);
    setToken(currentToken);
  }, []);

  return {
    authModaleStatus,
    setAuthModaleStatus: setCurrentAuthModaleStatus,
    loggedInUser,
    setLoggedInUser: setCurrentLoggedInUser,
    token,
    setToken: setCurrentToken,
  };
};
