import React from "react";

import PropTypes from "prop-types";
import ItemDetailAttributes from "./ItemDetailAttributes";
import { useP } from "../../../../services/i18n";
import clsx from "clsx";
import { getOpenSeaLink } from "../../../../utils/utils";
import { XMarkIcon } from "@heroicons/react/24/solid";

function ItemDetailBody({ item, collection, onClose }) {
  const p = useP();

  const getItemImage = (item) => {
    if (item?.media.length > 0) {
      return item?.media[0].thumbnail;
    } else {
      return `${
        process.env.REACT_APP_API_URL
      }/images/${collection.code.toLowerCase()}/default.png`;
    }
  };

  return (
    <div className="p-4">
      <div
        className="absolute right-3 top-3 rounded-full hover:bg-gray-200 opacity-70 p-1 cursor-pointer"
        onClick={onClose}
      >
        <XMarkIcon className="w-5 h-5 text-gray-500 group-hover:text-gray-900" />
      </div>
      <div className="aspect-w-1 aspect-h-1 w-full overflow-hidden">
        <img
          src={getItemImage(item)}
          alt={item.imageAlt}
          className="transition duration-300 h-full w-full object-cover object-center group-hover:scale-110 rounded-lg"
        />
      </div>
      <div className="space-y-4 mt-4 ">
        <div className="space-y-4">
          <div className="">
            <div className="cursor-default " onClick={() => {}}>
              <p className="text-lg font-medium text-transparent bg-clip-text bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 cursor-default">
                {collection.label}
              </p>
            </div>
            <h3
              className="mt-1 font-semibold text-slate-900 hover:text-slate-800 cursor-default text-xl"
              onClick={() => {}}
            >
              {item.title}
            </h3>
          </div>
          <div className="space-y-2">
            <h3 className="text-md font-semibold leading-6 text-gray-900 cursor-default">
              {p.t("accountPage.wallet.itemDetail.description")} :
            </h3>
            <div className="text-justify text-sm text-slate-600">
              {item.description}
            </div>
          </div>
          <div className="pt-2 pb-4">
            <ItemDetailAttributes attributes={item.rawMetadata.attributes} />
          </div>

          <div className="pt-2">
            <a
              className={clsx(
                "w-full inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-3 py-3 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-offset-2 cursor-pointer"
              )}
              href={getOpenSeaLink(
                collection.network.code,
                collection.contractAddress,
                item.tokenId
              )}
              target="_blank"
              rel="noreferrer"
            >
              {p.t("accountPage.wallet.itemDetail.showOnOpensea")}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

ItemDetailBody.propTypes = {
  item: PropTypes.object,
  collection: PropTypes.object,
  onClose: PropTypes.func,
};

export default ItemDetailBody;
