import React, { useContext, useState } from "react";
import { useP } from "../../../services/i18n";
import PropTypes from "prop-types";
import Input from "../../../components/Input";
import { useMutation } from "@apollo/client";
import { authContext } from "../../../AuthContext";
import {
  FORM_LOADING_STATE,
  FORM_SUCCESS_STATE,
  FORM_WAITING_STATE,
} from "../../../utils/constants";
import DynamicButton from "../../../components/DynamicButton";
import { SEND_RENEW_PASSWORD_REQUEST } from "../queries";
import { toast } from "react-hot-toast";

function RenewPasswordFormModal({ goBackToLogin }) {
  const p = useP();

  const { setAuthModaleStatus } = useContext(authContext);

  const [sendRenewPasswordRequest] = useMutation(SEND_RENEW_PASSWORD_REQUEST);

  const [currentState, setCurrentState] = useState(FORM_WAITING_STATE);

  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState();

  const onSubmit = async (e) => {
    e.preventDefault();

    setCurrentState(FORM_LOADING_STATE);

    const res = await sendRenewPasswordRequest({
      variables: {
        email,
      },
    });

    if (res?.data?.sendRenewPasswordRequest?.success) {
      setCurrentState(FORM_SUCCESS_STATE);
      setAuthModaleStatus("hidden");
      toast.success(
        p.t("navigation.authModal.renewPassword.renewPasswordSended")
      );
    } else {
      setCurrentState(FORM_WAITING_STATE);
      setErrorMessage(res?.data?.sendRenewPasswordRequest?.error);
    }
  };

  return (
    <form className="w-full space-y-10 text-left" onSubmit={onSubmit}>
      <div className="flex flex-col items-center justify-center w-full space-x-3">
        <div>
          <img src="/logo.png" className="w-12 h-w-12" />
        </div>
        <p className="text-xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-slate-800 to-slate-600">
          {p.t("navigation.authModal.title")}
        </p>
      </div>
      <div className="space-y-4">
        <Input
          type="email"
          name="email"
          label={p.t("navigation.authModal.fieldEmail")}
          value={email}
          setValue={setEmail}
          required={true}
        />
      </div>

      {errorMessage && (
        <div className="text-red-700">{p.t(`errors.${errorMessage}`)}</div>
      )}

      <div className="space-y-8">
        <div className="inline-flex space-x-2 w-full">
          <DynamicButton
            type="submit"
            label={p.t("commons.continue")}
            currentState={currentState}
          />
        </div>

        <div className="w-full mb-12 space-y-4 text-center">
          <p className="text-md italic text-gray-500">
            <span
              href="#"
              target="_blank"
              className="cursor-pointer font-semibold italic "
              onClick={goBackToLogin}
            >
              {p.t("navigation.authModal.renewPassword.goBackToLogin")}{" "}
            </span>
          </p>
        </div>
      </div>
    </form>
  );
}

RenewPasswordFormModal.propTypes = {
  goBackToLogin: PropTypes.func,
};

export default RenewPasswordFormModal;
