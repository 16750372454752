/* eslint-disable no-unused-vars */
// import { HeartIcon } from "@heroicons/react/24/solid";
import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import DynamicButton from "../../components/DynamicButton";
import Input from "../../components/Input";
import Spinner from "../../components/Spinner";
import { useP } from "../../services/i18n";
import {
  FORM_LOADING_STATE,
  FORM_SUCCESS_STATE,
  FORM_WAITING_STATE,
} from "../../utils/constants";
import Footer from "../Footer/Footer";
import { CHECK_RENEW_PASSWORD, RENEW_PASSWORD_REQUEST } from "./queries";

function RenewPasswordPage() {
  const p = useP();
  const navigate = useNavigate();

  const [currentState, setCurrentState] = useState(FORM_WAITING_STATE);

  const [
    checkRenewPassword,
    { data: checkRenewPasswordData, loading: checkRenewPasswordLoading },
  ] = useLazyQuery(CHECK_RENEW_PASSWORD, {
    fetchPolicy: "network-only",
  });

  const [renewPassword] = useMutation(RENEW_PASSWORD_REQUEST);

  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [errorMessage, setErrorMessage] = useState();

  const onSubmitForm = async (e) => {
    e.preventDefault();

    if (password !== passwordConfirm) {
      setErrorMessage("notSamePassword");
      return;
    }

    setCurrentState(FORM_LOADING_STATE);

    const res = await renewPassword({
      variables: {
        input: {
          token,
          password,
        },
      },
    });

    if (res?.data?.renewPassword?.success) {
      setCurrentState(FORM_SUCCESS_STATE);
      toast.success(p.t("renewPasswordPage.passwordChanged"));
      navigate("/", { replace: true });
    } else {
      setCurrentState(FORM_WAITING_STATE);
      setErrorMessage(res?.data?.renewPassword?.error);
    }
  };

  const checkRenewPasswordToken = async () => {
    const res = await checkRenewPassword({
      variables: {
        token,
      },
    });

    if (!res.data.checkRenewPasswordRequest.success) {
      toast.error(p.t("renewPasswordPage.expiredToken"));
      navigate("/", { replace: true });
    }
  };

  const [queryParams] = useSearchParams();

  useEffect(() => {
    if (queryParams.get("key")) {
      setToken(queryParams.get("key"));
    }
  }, []);

  useEffect(() => {
    if (token) {
      checkRenewPasswordToken();
    }
  }, [token]);

  return (
    <>
      <div className="flex-1 flex flex-col justify-center items-center bg-slate-50">
        <div className="flex flex-col h-full md:h-fit">
          <div className="flex-1 flex flex-col justify-center items-center md:py-10">
            {!checkRenewPasswordData || checkRenewPasswordLoading ? (
              <Spinner />
            ) : (
              <div className="h-full w-full md:max-w-xl md:h-full  md:shadow-xl md:rounded-3xl bg-white py-6 px-2">
                <div className="h-full w-full px-4 sm:px-4 lg:px-8 py-2 md:py-4 space-y-10">
                  <form
                    className="flex flex-col w-full h-full md:h-fit space-y-10 md:space-y-20"
                    onSubmit={onSubmitForm}
                  >
                    <div className="mt-4">
                      <div className="text-center space-y-2">
                        <p className="text-4xl font-bold tracking-tight text-gray-900">
                          {p.t("renewPasswordPage.title")}
                        </p>
                      </div>
                    </div>

                    <div className="flex flex-col flex-1 md:flex-0 items-center">
                      <div className="w-full space-y-12">
                        <div className="w-full">
                          <p className="text-md text-gray-500 text-justify">
                            {p.t("renewPasswordPage.description")}
                          </p>
                        </div>
                        <div className="space-y-4">
                          <div className="">
                            <Input
                              type="password"
                              name="password"
                              label={p.t("renewPasswordPage.newPassword")}
                              required={true}
                              value={password}
                              setValue={setPassword}
                              autoComplete="off"
                            />
                          </div>
                          <div className="">
                            <Input
                              type="password"
                              name="password-confirm"
                              label={p.t(
                                "renewPasswordPage.confirmNewPassword"
                              )}
                              required={true}
                              value={passwordConfirm}
                              setValue={setPasswordConfirm}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                      </div>
                      {errorMessage && (
                        <div className="text-left w-full mt-4 text-red-600">
                          {p.t(`errors.${errorMessage}`)}
                        </div>
                      )}
                    </div>

                    <div className="inline-flex space-x-2">
                      <DynamicButton
                        type="submit"
                        currentState={currentState}
                        label={p.t("commons.confirm")}
                      />
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default RenewPasswordPage;
