import { useState } from "react";
import PropTypes from "prop-types";

import { useP } from "../../../../../services/i18n";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ADD_CLAIM_CODE_TAG, GET_TAGS } from "../../../queries";
import { useEffect } from "react";
import { PlusIcon } from "@heroicons/react/24/outline";
import Tag from "../../../../../components/Tag";

// eslint-disable-next-line no-unused-vars
export default function TagItemMeny({
  selectedCollection,
  claimToken,
  onRemoveTag,
  loadClaimCodes,
}) {
  // eslint-disable-next-line no-unused-vars
  const p = useP();

  const [getTagsData, { data: tagsData }] = useLazyQuery(GET_TAGS, {
    fetchPolicy: "network-only",
  });

  const [addClaimTokenTag] = useMutation(ADD_CLAIM_CODE_TAG);

  const [newTagValue, setNewTagValue] = useState("");
  const [editionMode, setEditionMode] = useState(false);

  const getTags = () => {
    return claimToken.tags.map((tag, index) => (
      <Tag
        key={index}
        tag={tag}
        onRemove={() => onRemoveTag(claimToken, tag)}
      />
    ));
  };

  const onToggleNewClaimTokenTag = async (checked, tag) => {
    if (checked) {
      await addClaimTokenTag({
        variables: {
          claimCode: claimToken.token,
          tag: {
            id: tag.id,
          },
        },
      });
    } else {
      onRemoveTag(claimToken, tag);
    }
  };

  const onAddNewClaimTokenTag = async () => {
    await addClaimTokenTag({
      variables: {
        claimCode: claimToken.token,
        tag: {
          label: newTagValue,
        },
      },
    });

    setNewTagValue("");
    loadClaimCodes();
  };

  const loadTags = () => {
    getTagsData({
      variables: {
        collectionCode: selectedCollection.code,
      },
    });
  };

  const onClose = () => {
    loadClaimCodes();
    setEditionMode(false);
  };

  useEffect(() => {
    loadTags();
  }, []);

  if (!editionMode) {
    return (
      <div className="space-y-4">
        {!!claimToken.tags.length && (
          <div className="flex flex-col text-slate-500 space-y-2">
            {getTags()}
          </div>
        )}
        <div className="flex justify-start">
          <div
            className=" bg-slate-200 hover:bg-slate-300 w-fit rounded-md p-0.5 cursor-pointer"
            onClick={() => setEditionMode(true)}
          >
            <PlusIcon className="h-4 w-4 text-slate-600 hover:text-slate-700" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="">
      <div className="space-y-4">
        <div className="space-y-1">
          {tagsData?.tags?.map((tag, tagIdx) => (
            <div key={tagIdx} className="relative flex items-start space-x-2">
              <div className="flex h-5 items-center">
                <input
                  id={`tag-${tag.id}`}
                  name={`tag-${tag.id}`}
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  checked={claimToken.tags.find((item) => item.id === tag.id)}
                  onChange={(e) =>
                    onToggleNewClaimTokenTag(e.target.checked, tag)
                  }
                />
              </div>
              <div className="min-w-0 flex-1 text-sm">
                <label
                  htmlFor={`tag-${tag.id}`}
                  className="select-none font-medium text-gray-700"
                >
                  {tag.label}
                </label>
              </div>
            </div>
          ))}
        </div>
        <div className="flex flex-row space-x-2 border-slate-200 border-t pt-4">
          <input
            type="text"
            autoComplete="off"
            className="rounded-md border-gray-300 shadow-sm focus:border-slate-500 focus:ring-slate-500 text-sm p-0.5"
            required={true}
            value={newTagValue}
            onChange={(e) => setNewTagValue(e.target.value)}
            disabled={false}
          />
          <div
            className="flex bg-green-600 hover:bg-green-700 w-fit p-1 rounded-md cursor-pointer items-center"
            onClick={onAddNewClaimTokenTag}
          >
            <PlusIcon className="w-4 h-4 text-white" />
          </div>
        </div>

        <div className="flex flex-row space-x-2 border-slate-200 border-t pt-4">
          <div
            className="flex bg-blue-700 hover:bg-blue-800 w-full px-2 py-1 rounded-md cursor-pointer justify-center items-center text-white"
            onClick={() => onClose()}
          >
            {p.t("commons.close")}
          </div>
        </div>
      </div>
    </div>
  );
}

TagItemMeny.propTypes = {
  selectedCollection: PropTypes.object,
  claimToken: PropTypes.object,
  onRemoveTag: PropTypes.func,
  loadClaimCodes: PropTypes.func,
};
