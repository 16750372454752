import React from "react";
import { getNetworkLogo, OpenSeaSvg } from "../../../utils/networks";

import PropTypes from "prop-types";

import { Tooltip } from "react-tooltip";
import { useP } from "../../../services/i18n";
import { getOpenSeaLink } from "../../../utils/utils";

function WalletCollectionItems({ collection, onSelectItem }) {
  const getItemImage = (item) => {
    // return `${
    //   process.env.REACT_APP_API_URL
    // }/images/${collection.code.toLowerCase()}/${
    //   collection.revealed ? item.tokenId : "default"
    // }.png`;

    if (item?.media.length > 0) {
      return item?.media[0].thumbnail;
    } else {
      return `${
        process.env.REACT_APP_API_URL
      }/images/${collection.code.toLowerCase()}/default.png`;
    }

    // return "/images/thumbnails/no-blockchain.png";
  };
  const p = useP();
  return (
    <>
      {collection.items.map((item, index) => (
        <li
          key={index}
          className="group transition duration-300 inline-flex w-64 flex-col text-center lg:w-auto bg-white border border-slate-200 p-4 rounded-lg shadow-md hover:shadow-lg"
        >
          <div className="relative">
            <div
              className="aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-md bg-slate-200 cursor-pointer"
              onClick={() => onSelectItem({ collection, item })}
            >
              <img
                src={getItemImage(item)}
                alt={item.imageAlt}
                className="transition duration-300 h-full w-full object-cover object-center group-hover:scale-110"
              />
            </div>
            <div className="mt-6">
              <div className="cursor-default" onClick={() => {}}>
                <p className="text-sm font-medium text-transparent bg-clip-text bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
                  {collection.label}
                </p>
              </div>
              <h3
                className="mt-1 font-semibold text-slate-900 hover:text-slate-800 cursor-pointer"
                onClick={() => {}}
              >
                <a href={item.href}>{item.title}</a>
              </h3>
            </div>
            <div className="mt-auto flex items-center justify-center space-x-3 pt-4 pb-2">
              <div
                id={`network-icon-${collection.code}-${index}`}
                className="flex items-center justify-center rounded-full bg-slate-100 border-2 border-slate-200 w-8 h-8 hover:opacity-80"
              >
                {getNetworkLogo(collection.network.code, {
                  className: "w-4 h-4",
                })}
              </div>
              <a
                href={getOpenSeaLink(
                  collection.network.code,
                  collection.contractAddress,
                  item.tokenId
                )}
                target="_blank"
                rel="noreferrer"
              >
                <OpenSeaSvg className="w-8 h-8 hover:opacity-80" />
              </a>
            </div>
          </div>

          <Tooltip
            anchorId={`network-icon-${collection.code}-${index}`}
            content={`${p.t("accountPage.wallet.network")} ${
              collection.network.label
            }`}
            place="bottom"
          />
        </li>
      ))}
    </>
  );
}

WalletCollectionItems.propTypes = {
  collection: PropTypes.object,
  onSelectItem: PropTypes.func,
};

export default WalletCollectionItems;
