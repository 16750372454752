const FORM_LOADING_STATE = "LOADING";
const FORM_ERROR_STATE = "ERROR";
const FORM_SUCCESS_STATE = "SUCCESS";
const FORM_WAITING_STATE = "WAITING";

const NETWORK_ETHEREUM_CODE = "ETH";
const NETWORK_POLYGON_CODE = "POLYGON";
const NETWORK_ARBITRUM_CODE = "ARBITRUM";
const NETWORK_AVALANCHE_CODE = "AVALANCHE";
const NETWORK_BSC_CODE = "BSC";
const NETWORK_KLAYTN_CODE = "KLAYTN";
const NETWORK_OPTIMISM_CODE = "OPTIMISM";
const NETWORK_SOLANA_CODE = "SOLANA";

const ADVANTAGE_TYPE_CATAPULT_INVITE = "CATAPULT_INVITE";
const ADVANTAGE_TYPE_GIFT_CARD = "GIFT_CARD";

const STATUS_ACTIVE = "ACTIVE";
const STATUS_BURNED = "BURNED";
const STATUS_USED = "USED";
const STATUS_SENDED = "SENDED";

export {
  FORM_LOADING_STATE,
  FORM_ERROR_STATE,
  FORM_SUCCESS_STATE,
  FORM_WAITING_STATE,
  NETWORK_ETHEREUM_CODE,
  NETWORK_POLYGON_CODE,
  NETWORK_ARBITRUM_CODE,
  NETWORK_AVALANCHE_CODE,
  NETWORK_BSC_CODE,
  NETWORK_KLAYTN_CODE,
  NETWORK_OPTIMISM_CODE,
  NETWORK_SOLANA_CODE,
  ADVANTAGE_TYPE_CATAPULT_INVITE,
  ADVANTAGE_TYPE_GIFT_CARD,
  STATUS_ACTIVE,
  STATUS_USED,
  STATUS_BURNED,
  STATUS_SENDED,
};
