import { useCallback, useState } from "react";

export const useClaimPage = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [collection, setCollection] = useState(false);
  const [activationCode, setActivationCode] = useState("");
  const [contactEmails, setContactEmails] = useState("");
  const [newsletterEnabled, setNewsletterEnabled] = useState("");

  const setCurrentActiveStep = useCallback((currentActiveStep) => {
    setActiveStep(currentActiveStep);
  }, []);

  const setCurrentCollection = useCallback((currentCollection) => {
    setCollection(currentCollection);
  }, []);

  const setCurrentActivationCode = useCallback((currentActivationCode) => {
    setActivationCode(currentActivationCode);
  }, []);

  const setCurrentNewsletterEnabled = useCallback(
    (currentNewsletterEnabled) => {
      setNewsletterEnabled(currentNewsletterEnabled);
    },
    []
  );

  const setCurrentContactEmails = useCallback((currentContactEmails) => {
    setContactEmails(currentContactEmails);
  }, []);

  return {
    activeStep,
    setActiveStep: setCurrentActiveStep,
    collection,
    setCollection: setCurrentCollection,
    activationCode,
    setActivationCode: setCurrentActivationCode,
    newsletterEnabled,
    setNewsletterEnabled: setCurrentNewsletterEnabled,
    contactEmails,
    setContactEmails: setCurrentContactEmails,
  };
};
