import { useMutation } from "@apollo/client";
import React, { useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { authContext } from "../../AuthContext";
import { LOGOUT } from "./queries";

function LogoutPage() {
  const { token, setLoggedInUser, setToken } = useContext(authContext);

  const [logout] = useMutation(LOGOUT);

  useEffect(() => {
    if (token) {
      logout();
    }
    setToken(null);
    setLoggedInUser(null);
  }, []);

  return <Navigate to="/" replace={true} />;
}

export default LogoutPage;
