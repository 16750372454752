import React, { useContext } from "react";
import { useP } from "../../../../services/i18n";
import { claimPageContext } from "../../ClaimPageContext";

function ConfirmationCheckboxes() {
  const p = useP();

  return (
    <div className="space-y-4">
      <div className="relative flex items-start">
        <div className="flex h-5 items-center">
          <input
            id="comments"
            aria-describedby="comments-description"
            name="comments"
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            required={true}
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor="comments" className="font-medium text-gray-700">
            {p.t("claimPage.steps.confirmation.optinInformations.comment")}
          </label>
          <span id="comments-description" className="text-gray-500">
            <span className="sr-only">
              {p.t("claimPage.steps.confirmation.optinInformations.comment")}
            </span>
            {p.t("claimPage.steps.confirmation.optinInformations.description")}
          </span>
        </div>
      </div>

      <div className="relative flex items-start">
        <div className="flex h-5 items-center">
          <input
            id="comments"
            aria-describedby="comments-description"
            name="comments"
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            required={true}
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor="comments" className="font-medium text-gray-700">
            {p.t("claimPage.steps.confirmation.optinBurn.comment")}
          </label>
          <span id="comments-description" className="text-gray-500">
            <span className="sr-only">
              {p.t("claimPage.steps.confirmation.optinBurn.comment")}
            </span>
            {p.t("claimPage.steps.confirmation.optinBurn.description")}
          </span>
        </div>
      </div>

      <div className="relative flex items-start">
        <div className="flex h-5 items-center">
          <input
            id="candidates"
            aria-describedby="candidates-description"
            name="candidates"
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            required={true}
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor="candidates" className="font-medium text-gray-700">
            {p.t("claimPage.steps.confirmation.optinLegal.comment")}
          </label>
          <span id="candidates-description" className="text-gray-500">
            <span className="sr-only">
              {p.t("claimPage.steps.confirmation.optinLegal.comment")}
            </span>
            {p.t("claimPage.steps.confirmation.optinLegal.description_1")}
            <a
              href="#"
              className="font-semibold italic hover:text-blue-700 underline"
              target="_blank"
            >
              {p.t("claimPage.steps.confirmation.optinLegal.cgu")}
            </a>{" "}
            {p.t("claimPage.steps.confirmation.optinLegal.description_2")}
            <a
              href="#"
              className="font-semibold italic hover:text-blue-700 underline"
              target="_blank"
            >
              {p.t("claimPage.steps.confirmation.optinLegal.pc")}
            </a>
            {p.t("claimPage.steps.confirmation.optinLegal.description_3")}
          </span>
        </div>
      </div>
    </div>
  );
}

function ConfirmationStep() {
  const p = useP();

  const { collection } = useContext(claimPageContext);
  return (
    <div className="w-full space-y-12">
      <div className="w-full">
        <p className="text-xl text-gray-500">
          {p.t("claimPage.steps.confirmation.title")}
        </p>
      </div>

      <div className="space-y-4 text-md">
        <div className="border-b border-slate-200" />

        <div className="w-full mb-12 space-y-4">
          <p className="text-md text-gray-500 space-x-2 font-semibold">
            <span className="bg-slate-200 px-2 py-0.5 rounded-lg"> x 1</span>
            <span>{collection.label}</span>
          </p>
        </div>

        <div className="border-b border-slate-200" />
      </div>

      <div className="w-full text-justify">
        {p.t("claimPage.steps.confirmation.disclaimer")}
      </div>

      <ConfirmationCheckboxes />
    </div>
  );
}

export default ConfirmationStep;
