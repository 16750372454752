import { toast, Toaster } from "react-hot-toast";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";

import "./App.css";
import ClaimPage from "./pages/ClaimPage/ClaimPage";
import HomePage from "./pages/HomePage/HomePage";

import { useAuth } from "./UseAuthContext";
import { authContext } from "./AuthContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useContext, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { IS_LOGGED_IN } from "./queries";
import LogoutPage from "./pages/LogoutPage/LogoutPage";
import { useP } from "./services/i18n";
import MainContainer from "./MainContainer";
import AccountPage from "./pages/WalletPage/AccountPage";
import RenewPasswordPage from "./pages/RenewPassword/RenewPassword";
const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

function AppContainer() {
  const [isLoggedIn] = useLazyQuery(IS_LOGGED_IN, {
    fetchPolicy: "network-only",
  });

  const p = useP();
  const { token, loggedInUser, setLoggedInUser } = useContext(authContext);

  const checkLoggedInStatus = async () => {
    if (!token && !loggedInUser?.email) return;

    const res = await isLoggedIn();

    if (!res?.data?.isLoggedIn?.success) {
      toast.error(p.t("errors.sessionExpired"));
      window.location.href = "/logout";
    } else {
      setLoggedInUser(res?.data?.isLoggedIn?.loggedInUser);
    }
  };

  useEffect(() => {
    checkLoggedInStatus();
  }, [token]);

  useEffect(() => {
    const interval = setInterval(() => {
      checkLoggedInStatus();
    }, 300000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainContainer />}>
          <Route path="account" element={<AccountPage />} />
          <Route path="logout" element={<LogoutPage />} />
          <Route path="claim" element={<ClaimPage />} />
          <Route path="renew-password" element={<RenewPasswordPage />} />
          <Route path="*" element={<Navigate to="/" />} />
          <Route index element={<HomePage />} />
        </Route>
      </Routes>
    </Router>
  );
}

function App() {
  const authCtxt = useAuth();

  return (
    <div className="">
      <GoogleOAuthProvider clientId={clientId}>
        <authContext.Provider value={authCtxt}>
          <AppContainer />
          <Toaster position="bottom-right" />
        </authContext.Provider>
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
