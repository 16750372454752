import TokenStep from "./Steps/TokenStep";
import ConfirmationStep from "./Steps/ConfirmationStep";
import WalletStep from "./Steps/WalletStep";
import ReferralStep from "./Steps/Referal/ReferralStep";
import AuthStep from "./Steps/AuthStep";

const TOKEN_STEP = {
  code: "TOKEN_STEP",
  element: TokenStep,
  submitType: "continue",
};

const AUTH_STEP = {
  code: "AUTH_STEP",
  element: AuthStep,
  submitType: "hidden",
};

const WALLET_STEP = {
  code: "WALLET_STEP",
  element: WalletStep,
  submitType: "continue",
};

const CONFIRMATION_STEP = {
  code: "CONFIRMATION_STEP",
  element: ConfirmationStep,
  submitType: "confirm",
};

const REFERRAL_STEP = {
  code: "REFERRAL_STEP",
  element: ReferralStep,
  submitType: "finalize",
};

export { TOKEN_STEP, AUTH_STEP, WALLET_STEP, REFERRAL_STEP, CONFIRMATION_STEP };
