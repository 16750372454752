import React from "react";
import PropTypes from "prop-types";
import WalletAdvantageItem from "./WalletAdvantageItem/WalletAdvantageItem";

// const projects = [
//   {
//     name: "Graph API",
//     initials: "GA",
//     href: "#",
//     members: 16,
//     bgColor: "bg-pink-600",
//   },
//   {
//     name: "Component Design",
//     initials: "CD",
//     href: "#",
//     members: 12,
//     bgColor: "bg-purple-600",
//   },
//   {
//     name: "Templates",
//     initials: "T",
//     href: "#",
//     members: 16,
//     bgColor: "bg-yellow-500",
//   },
//   {
//     name: "React Components",
//     initials: "RC",
//     href: "#",
//     members: 8,
//     bgColor: "bg-green-500",
//   },
// ];

export default function WalletAdvantagesItems({
  advantages,
  onSelectAdvantage,
}) {
  return (
    <div>
      {/* <h2 className="text-sm font-medium text-gray-500">
        Disponibles dès maintenant !
      </h2> */}
      <ul
        role="list"
        className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-3"
      >
        {advantages.map((advantage, index) => (
          <WalletAdvantageItem
            key={index}
            advantage={advantage}
            onSelectAdvantage={onSelectAdvantage}
          />
        ))}
      </ul>
    </div>
  );
}

WalletAdvantagesItems.propTypes = {
  advantages: PropTypes.array,
  onSelectAdvantage: PropTypes.func,
};
