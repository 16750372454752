import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import React from "react";
import {
  FORM_ERROR_STATE,
  FORM_LOADING_STATE,
  FORM_SUCCESS_STATE,
  FORM_WAITING_STATE,
} from "../utils/constants";
import Button from "./Button";
import Spinner from "./Spinner";

import PropTypes from "prop-types";

function DynamicButton({ type, label, currentState }) {
  const getStateIcon = () => {
    let res = <></>;
    switch (currentState) {
      case FORM_LOADING_STATE:
        res = <Spinner color="white" size="h-4 w-4" borderWidth="sm" />;
        break;

      case FORM_ERROR_STATE:
        res = <XCircleIcon className="w-5 h-5 text-red-300" />;
        break;

      case FORM_SUCCESS_STATE:
        res = <CheckCircleIcon className="w-5 h-5 text-green-300" />;
        break;

      default:
        res = <></>;
        break;
    }

    return res;
  };

  return (
    <Button type={type}>
      {currentState === FORM_WAITING_STATE && label}
      <span className="ml-1 flex items-center">{getStateIcon()}</span>
    </Button>
  );
}

DynamicButton.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  currentState: PropTypes.string,
};

export default DynamicButton;
