import React, { useEffect, useState } from "react";
import WalletCollection from "./WalletCollection";
import PropTypes from "prop-types";
import { useP } from "../../../services/i18n";
import Spinner from "../../../components/Spinner";
import ItemDetails from "./ItemDetails/ItemDetails";

function WalletCollections({ loading, walletData }) {
  const p = useP();

  const [openItemDetail, setOpenItemDetail] = useState(false);
  const [selectedItem, setSelectedItem] = useState();

  const onSelectItem = (item) => {
    setSelectedItem(item);
  };

  useEffect(() => {
    if (selectedItem) {
      setOpenItemDetail(true);
    }
  }, [selectedItem]);

  return (
    <div className="flex flex-1 flex-col space-y-6 md:space-y-12 px-4 sm:px-6 lg:px-0">
      <h2 className="text-2xl font-bold tracking-tight text-slate-900 ">
        {p.t("accountPage.wallet.title")}
      </h2>
      <div className="space-y-10">
        {loading ? (
          <Spinner />
        ) : walletData?.creators && walletData?.creators.length ? (
          walletData.creators.map((creator, index) => (
            <WalletCollection
              key={index}
              creator={creator}
              onSelectItem={onSelectItem}
            />
          ))
        ) : (
          p.t("accountPage.wallet.noItems")
        )}
      </div>
      <ItemDetails
        open={openItemDetail}
        onClose={() => setOpenItemDetail(false)}
        selectedItem={selectedItem}
      />
    </div>
  );
}

WalletCollections.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  walletData: PropTypes.object,
};

export default WalletCollections;
