import clsx from "clsx";
import React from "react";

import PropTypes from "prop-types";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

import { getAdvantageDatas } from "../../../../utils/utils";
import { useP } from "../../../../services/i18n";
import dayjs from "dayjs";
import { STATUS_ACTIVE } from "../../../../utils/constants";

function WalletAdvantageItem({ advantage, onSelectAdvantage }) {
  const p = useP();

  const getIcon = () => {
    const Icon = getAdvantageDatas(advantage).icon;

    return (
      <div className="transition duration-300 group-hover:scale-125">
        <Icon className="w-5 h-5 " />
      </div>
    );
  };

  const getPrimaryColor = () => {
    return getAdvantageDatas(advantage).primaryColor;
  };

  const getStatus = () => {
    let res;
    if (advantage.statusCode !== STATUS_ACTIVE) {
      res = (
        <>
          {p.t("accountPage.advantages.status")} : {p.t("commons.used")}{" "}
        </>
      );
    } else {
      res = (
        <>
          {p.t("accountPage.advantages.expiration")} :{" "}
          {advantage.expirationDate
            ? dayjs(advantage.expirationDate).toDate().toLocaleDateString()
            : p.t("accountPage.advantages.noExpiry")}
        </>
      );
    }

    return res;
  };

  return (
    <li
      className="group transition duration-300 col-span-1 flex rounded-md shadow-sm hover:shadow-md cursor-pointer overflow-hidden"
      onClick={() => onSelectAdvantage(advantage)}
    >
      <div
        className={clsx(
          `bg-${getPrimaryColor()}`,
          "flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium"
        )}
      >
        {getIcon()}
      </div>
      <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-t border-r border-b border-gray-200 bg-white">
        <div className="flex-1 truncate px-4 py-2 text-sm">
          <p
            href={advantage.href}
            className="font-medium text-gray-900 hover:text-gray-600"
          >
            {advantage.title}
          </p>
          <p className="text-gray-500">
            {advantage.collection.owner.displayedName}
          </p>
          <p className="text-gray-500 mt-2">{getStatus()}</p>
        </div>
        <div className="flex-shrink-0 pr-2">
          <button
            type="button"
            className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-white bg-transparent text-gray-400 hover:text-gray-500 focus:outline-none"
          >
            <span className="sr-only">Open options</span>
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    </li>
  );
}

WalletAdvantageItem.propTypes = {
  advantage: PropTypes.object,
  onSelectAdvantage: PropTypes.func,
};

export default WalletAdvantageItem;
