import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useP } from "../../../services/i18n";
import Spinner from "../../../components/Spinner";
import CollectionItem from "./CollectionItem";
import CollectionDetails from "./CollectionDetails/CollectionDetails";

function WalletOwnedCollections({ loading, ownerData }) {
  const p = useP();

  const [selectedCollection, setSelectedCollection] = useState(null);
  const [collectionDetailsOpen, setCollectionDetailsOpen] = useState(false);

  const onCloseCollectionDetails = () => {
    setCollectionDetailsOpen(false);
  };

  const onSelectCollection = (collection) => {
    setSelectedCollection(collection);
    setCollectionDetailsOpen(true);
  };

  useEffect(() => {
    if (!collectionDetailsOpen) {
      setSelectedCollection(null);
    }
  }, [collectionDetailsOpen]);

  return (
    <div className="flex flex-1 flex-col space-y-6 md:space-y-12 px-4 sm:px-6 lg:px-0">
      <h2 className="text-2xl font-bold tracking-tight text-slate-900 ">
        {p.t("accountPage.owner.title")}
      </h2>
      <div>
        {loading ? (
          <Spinner />
        ) : ownerData && ownerData.length ? (
          <div className="overflow-hidden bg-white shadow sm:rounded-md">
            <ul role="list" className="divide-y divide-gray-200">
              {ownerData.map((collection, index) => (
                <CollectionItem
                  key={index}
                  collection={collection}
                  onSelectCollection={onSelectCollection}
                />
              ))}
            </ul>
          </div>
        ) : (
          p.t("accountPage.owner.noItems")
        )}
      </div>
      <CollectionDetails
        selectedCollection={selectedCollection}
        open={collectionDetailsOpen}
        onClose={onCloseCollectionDetails}
      />
    </div>
  );
}

WalletOwnedCollections.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  ownerData: PropTypes.array,
};

export default WalletOwnedCollections;
