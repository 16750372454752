import {
  GiftIcon,
  LockClosedIcon,
  TicketIcon,
} from "@heroicons/react/24/solid";
import {
  ADVANTAGE_TYPE_CATAPULT_INVITE,
  ADVANTAGE_TYPE_GIFT_CARD,
  STATUS_ACTIVE,
} from "./constants";

import QRCode from "qrcode";
import { saveAs } from "file-saver";

const getMinifiedKey = (token, size = 4) => {
  return (
    token.substring(0, size) + "..." + token.substring(token.length - size)
  );
};

const getOpenSeaLink = (network, contractAddress, tokenId) => {
  let link = "https://opensea.io/fr/assets/";

  switch (network) {
    case "POLYGON":
      link += "matic/";
      break;

    case "ETHEREUM":
      link += "ethereum/";
      break;
  }

  link += `${contractAddress}/${tokenId}`;

  return link;
};

const copyToClipboard = (str, callback = () => null) => {
  const el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);

  callback();
};

const downloadQRCode = async (url, fileName) => {
  const qrDataUrl = await QRCode.toDataURL(url);
  const blob = await fetch(qrDataUrl).then((r) => r.blob());
  saveAs(blob, `${fileName}.png`);
};

const getAdvantageDatas = (advantage) => {
  let datas = {
    primaryColor: "slate-300",
    secondaryColor: "slate-300",
    icon: LockClosedIcon,
  };

  if (advantage.statusCode !== STATUS_ACTIVE) {
    return datas;
  }

  switch (advantage?.type?.code) {
    case ADVANTAGE_TYPE_CATAPULT_INVITE:
      datas.primaryColor = "blue-500";
      datas.secondaryColor = "blue-200";
      datas.icon = GiftIcon;
      break;

    case ADVANTAGE_TYPE_GIFT_CARD:
      datas.primaryColor = "purple-500";
      datas.secondaryColor = "purple-300";
      datas.icon = TicketIcon;
      break;

    default:
      break;
  }

  return datas;
};

export {
  getMinifiedKey,
  copyToClipboard,
  getOpenSeaLink,
  getAdvantageDatas,
  downloadQRCode,
};
