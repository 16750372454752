import React from "react";
import PropTypes from "prop-types";
import { useP } from "../../../../services/i18n";

function ItemDetailAttributes({ attributes = [] }) {
  const p = useP();

  return (
    <div>
      <h3 className="text-md font-semibold leading-6 text-gray-900 cursor-default">
        {p.t("accountPage.wallet.itemDetail.attributes")} :
      </h3>
      <ul
        role="list"
        className="mt-3 text-sm grid grid-cols-1 gap-2 md:grid-cols-2 sm:gap-2 lg:grid-cols-2"
      >
        {attributes.map((item, index) => (
          <li
            key={index}
            className="col-span-1 flex-col rounded-md shadow-sm border-2 border-blue-100 bg-blue-50 text-center p-1"
          >
            <div className="text-blue-400 text-semibold">{item.trait_type}</div>
            <div className="font-medium text-slate-800">{item.value}</div>
          </li>
        ))}
      </ul>
    </div>
  );
}

ItemDetailAttributes.propTypes = {
  attributes: PropTypes.array,
};

export default ItemDetailAttributes;
