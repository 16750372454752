import React, { Fragment, useContext, useState } from "react";
import PropTypes from "prop-types";
import {
  TOKEN_STEP,
  CONFIRMATION_STEP,
  REFERRAL_STEP,
  AUTH_STEP,
} from "./FormSteps";

import ClaimPageTitle from "../ClaimPageTitle";
import { useP } from "../../../services/i18n";
import { toast } from "react-hot-toast";
import { claimPageContext } from "../ClaimPageContext";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CLAIM, TOKEN_CLAIM_VALIDATION } from "./queries";
import { useEffect } from "react";
import { authContext } from "../../../AuthContext";
import {
  FORM_ERROR_STATE,
  FORM_LOADING_STATE,
  FORM_SUCCESS_STATE,
  FORM_WAITING_STATE,
} from "../../../utils/constants";
import DynamicButton from "../../../components/DynamicButton";

function ClaimPageStep({ elem: Elem = Fragment }) {
  return <Elem />;
}

ClaimPageStep.propTypes = {
  elem: PropTypes.any,
};

function ClaimForm() {
  const p = useP();

  const [tokenClaimValidation, { data: tokenClaimData }] = useLazyQuery(
    TOKEN_CLAIM_VALIDATION,
    {
      fetchPolicy: "network-only",
    }
  );
  const [claim] = useMutation(CLAIM);

  const { activeStep, setActiveStep, activationCode, setCollection } =
    useContext(claimPageContext);

  const { loggedInUser } = useContext(authContext);

  const [currentState, setCurrentState] = useState(FORM_WAITING_STATE);
  const [errorCode, setErrorCode] = useState();

  const formSteps = loggedInUser?.email
    ? [TOKEN_STEP, CONFIRMATION_STEP, REFERRAL_STEP]
    : [AUTH_STEP];

  const nextStep = () => {
    if (activeStep + 1 < formSteps.length) {
      setActiveStep(activeStep + 1);
    }
  };

  const setError = () => {
    setCurrentState(FORM_ERROR_STATE);
    sleep(1000).then(() => {
      setCurrentState(FORM_WAITING_STATE);
    });
  };

  const setSuccess = () => {
    setCurrentState(FORM_SUCCESS_STATE);
    sleep(1000).then(() => {
      setErrorCode(null);
      nextStep();
      setCurrentState(FORM_WAITING_STATE);
    });
  };

  const _onContinue = () => {
    setCurrentState(FORM_LOADING_STATE);

    sleep(1000).then(() => {
      setSuccess();
    });
  };

  const _onConfirm = () => {
    toast.success("Votre NFT est en route!");
    setCurrentState(FORM_LOADING_STATE);

    sleep(1000).then(() => {
      let tmp = true;

      if (tmp) {
        setSuccess();
      } else {
        setError();
      }
    });
  };

  const _onError = (message) => {
    setCurrentState(FORM_LOADING_STATE);

    sleep(1000).then(() => {
      setErrorCode(message);
      setError();
    });
  };

  const _onFinalize = () => {
    toast.success("Envoi traité avec succès!");

    setCurrentState(FORM_LOADING_STATE);

    sleep(1000).then(() => {
      window.location.href = "/account";
    });
  };

  const _checkToken = async () => {
    setCurrentState(FORM_LOADING_STATE);

    const res = await tokenClaimValidation({
      variables: {
        token: activationCode,
      },
    });

    return {
      success: !!res?.data?.tokenClaimValidation?.success,
      error: res?.data?.tokenClaimValidation?.error,
    };
  };

  const _claimToken = async () => {
    setCurrentState(FORM_LOADING_STATE);
    const res = await claim({
      variables: {
        claim: {
          token: activationCode,
        },
      },
    });

    return {
      success: !!res?.data?.claim?.success,
      error: res?.data?.claim?.error,
    };
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();

    let res;

    switch (formSteps[activeStep]?.code) {
      case "TOKEN_STEP":
        res = await _checkToken();
        break;
      case "CONFIRMATION_STEP":
        res = await _claimToken();
        break;
      default:
        res = { success: true };
        break;
    }

    if (!res.success) {
      _onError(res.error);
      return;
    }

    switch (formSteps[activeStep]?.submitType) {
      case "confirm":
        _onConfirm();
        break;

      case "finalize":
        _onFinalize();
        break;

      default:
        _onContinue();
        break;
    }
  };

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  useEffect(() => {
    if (tokenClaimData?.tokenClaimValidation) {
      setCollection(tokenClaimData?.tokenClaimValidation?.collection);
    }
  }, [tokenClaimData]);

  return (
    <>
      <form
        className="flex flex-col w-full h-full md:h-fit space-y-10 md:space-y-20"
        onSubmit={onSubmitForm}
      >
        <div className="mt-4">
          <ClaimPageTitle
            collection={
              formSteps[activeStep]?.code !== "TOKEN_STEP" &&
              tokenClaimData?.tokenClaimValidation?.collection
            }
          />
        </div>

        <div className="flex flex-col flex-1 md:flex-0 items-center">
          <ClaimPageStep elem={formSteps[activeStep]?.element} />
          {errorCode && (
            <div className="text-left w-full mt-4 text-red-600">
              {p.t(`errors.${errorCode}`)}
            </div>
          )}
        </div>

        {formSteps[activeStep]?.submitType !== "hidden" && (
          <div className="inline-flex space-x-2">
            <DynamicButton
              type="submit"
              currentState={currentState}
              label={
                formSteps[activeStep]
                  ? p.t(`commons.${formSteps[activeStep]?.submitType}`)
                  : p.t("commons.continue")
              }
            />
          </div>
        )}
      </form>
    </>
  );
}

export default ClaimForm;
