import { gql } from "@apollo/client";

const RENEW_PASSWORD_REQUEST = gql`
  mutation RenewPassword($input: RenewPasswordRequestInput!) {
    renewPassword(input: $input) {
      error
      message
      success
    }
  }
`;

const CHECK_RENEW_PASSWORD = gql`
  query CheckRenewPassword($token: String!) {
    checkRenewPasswordRequest(token: $token) {
      success
    }
  }
`;

export { RENEW_PASSWORD_REQUEST, CHECK_RENEW_PASSWORD };
