import { createContext } from "react";

export const CLAIM_PAGE_DEFAULT_VALUE = {
  currentStep: 0,
  setCurrentStep: null,

  collection: null,
  setCollection: null,

  activationCode: "",
  setActivationCode: null,

  newsletter: "",
  setNewsletter: null,

  contactEmails: [],
  setContactEmails: null,
};

export const claimPageContext = createContext(CLAIM_PAGE_DEFAULT_VALUE);
