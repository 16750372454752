import React from "react";

import clsx from "clsx";

import PropTypes from "prop-types";

function Button({ type, onClick = () => null, children }) {
  return (
    <button
      className={clsx(
        "mb-4 w-full inline-flex items-center justify-center rounded-md border border-transparent bg-slate-900 px-3 py-3 text-sm font-medium text-white shadow-sm hover:bg-slate-800 focus:outline-none focus:ring-2 focus:ring-slate-700 focus:ring-offset-2 cursor-pointer"
      )}
      type={type}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

Button.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.any,
};

export default Button;
