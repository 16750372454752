import { useLazyQuery, useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import Footer from "../Footer/Footer";
import { GET_OWNER_DATA, GET_WALLET_DATA } from "./queries";
import WalletAdvantages from "./WalletAdvantages/WalletAdvantages";
import WalletCollections from "./WalletCollections/WalletCollections";
import WalletOwnedCollections from "./WalletOwner/WalletOwnedCollections";

function AccountPage() {
  const [
    getWalletData,
    { data: walletData, loading: walletDataLoading, error: walletDataError },
  ] = useLazyQuery(GET_WALLET_DATA, {
    fetchPolicy: "network-only",
  });

  const {
    data: ownerData,
    loading: ownerDataLoading,
    error: ownerDataError,
  } = useQuery(GET_OWNER_DATA, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    getWalletData();
  }, []);

  return (
    <>
      <div className="flex-1 flex flex-col items-center bg-slate-50">
        <div className="max-w-7xl w-full py-16 px-4 sm:py-24 sm:px-6 lg:px-8 space-y-12">
          <WalletAdvantages
            loading={walletDataLoading}
            error={walletDataError}
            walletData={walletData?.walletData}
            getWalletData={getWalletData}
          />

          {ownerData?.ownerData.length > 0 ? (
            <WalletOwnedCollections
              loading={ownerDataLoading}
              error={ownerDataError}
              ownerData={ownerData?.ownerData}
            />
          ) : (
            <></>
          )}

          <WalletCollections
            loading={walletDataLoading}
            error={walletDataError}
            walletData={walletData?.walletData}
          />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AccountPage;
