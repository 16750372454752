import { Fragment, useRef, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import PropTypes from "prop-types";
import {
  CheckIcon,
  EllipsisVerticalIcon,
  EnvelopeIcon,
  LockClosedIcon,
  // PencilIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useP } from "../../../../../services/i18n";
import { usePopper } from "react-popper";
import { Portal } from "react-portal";
import { toast } from "react-hot-toast";

export default function ActivationCodeItemMenu({
  claimToken,
  // onClickEdit,
  onClickSend,
  onClickActivate,
  onClickDesactivate,
  onClickDelete,
}) {
  const p = useP();

  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: "bottom",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  const onClickSendNoSendTo = () => {
    toast.error(p.t("toasts.noSendTo"));
  };

  return (
    <div className="relative text-right">
      <Menu>
        {({ open }) => (
          <>
            <div ref={setTargetElement} className="">
              <Menu.Button className="">
                <EllipsisVerticalIcon className="h-5 w-5 text-slate-600 hover:text-slate-700" />
              </Menu.Button>
            </div>
            <Portal>
              <div
                ref={popperElRef}
                style={styles.popper}
                {...attributes.popper}
                className="z-20"
              >
                <Transition
                  show={open}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                  beforeEnter={() => setPopperElement(popperElRef.current)}
                  afterLeave={() => setPopperElement(null)}
                >
                  <Menu.Items className=" mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="px-1 py-1">
                      <Menu.Item disabled={!claimToken.sendTo}>
                        {({ active }) => (
                          <>
                            <button
                              className={`${
                                active
                                  ? "bg-blue-500 text-white"
                                  : "text-gray-900"
                              } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                              onClick={
                                claimToken.sendTo
                                  ? onClickSend
                                  : onClickSendNoSendTo
                              }
                            >
                              {claimToken.sendTo ? (
                                <EnvelopeIcon
                                  className="mr-2 h-5 w-5"
                                  aria-hidden="true"
                                />
                              ) : (
                                <LockClosedIcon
                                  className="mr-2 h-5 w-5"
                                  aria-hidden="true"
                                />
                              )}

                              {p.t(
                                "accountPage.owner.collectionDetails.activationCodes.send"
                              )}
                            </button>
                          </>
                        )}
                      </Menu.Item>
                    </div>
                    <div className="px-1 py-1">
                      {claimToken?.status.code === "ACTIVE" ? (
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={`${
                                active
                                  ? "bg-blue-500 text-white"
                                  : "text-gray-900"
                              } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                              onClick={onClickDesactivate}
                            >
                              <XMarkIcon
                                className="mr-2 h-5 w-5"
                                aria-hidden="true"
                              />
                              {p.t(
                                "accountPage.owner.collectionDetails.activationCodes.desactivate"
                              )}
                            </button>
                          )}
                        </Menu.Item>
                      ) : (
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={`${
                                active
                                  ? "bg-blue-500 text-white"
                                  : "text-gray-900"
                              } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                              onClick={onClickActivate}
                            >
                              <CheckIcon
                                className="mr-2 h-5 w-5"
                                aria-hidden="true"
                              />
                              {p.t(
                                "accountPage.owner.collectionDetails.activationCodes.activate"
                              )}
                            </button>
                          )}
                        </Menu.Item>
                      )}
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={`${
                              active
                                ? "bg-blue-500 text-white"
                                : "text-gray-900"
                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                            onClick={onClickDelete}
                          >
                            <TrashIcon
                              className="mr-2 h-5 w-5 text-blue-400"
                              aria-hidden="true"
                            />
                            {p.t(
                              "accountPage.owner.collectionDetails.activationCodes.delete"
                            )}
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </div>
            </Portal>
          </>
        )}
      </Menu>
    </div>
  );
}

ActivationCodeItemMenu.propTypes = {
  claimToken: PropTypes.object,
  onClickEdit: PropTypes.func,
  onClickSend: PropTypes.func,
  onClickActivate: PropTypes.func,
  onClickDesactivate: PropTypes.func,
  onClickDelete: PropTypes.func,
};
