import React, { Fragment, useContext } from "react";
import { Menu, Transition } from "@headlessui/react";
import clsx from "clsx";
import { useP } from "../../../services/i18n";
import { Cog8ToothIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { authContext } from "../../../AuthContext";

function ProfileDropdown({ soon }) {
  const p = useP();
  const navigate = useNavigate();

  const { loggedInUser } = useContext(authContext);

  return (
    <Menu as="div" className="relative ml-3 my-auto">
      <div>
        <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none border border-slate-200 p-2 hover:bg-slate-100">
          <span className="sr-only">Open user menu</span>
          <Cog8ToothIcon className="w-5 h-5 text-slate-500" />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute divide-y right-0 z-10 mt-2 w-fit origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div>
            <div className="w-full block px-4 py-2 text-sm text-gray-700 text-left">
              {loggedInUser?.email}
            </div>
          </div>
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  type="button"
                  onClick={soon}
                  className={clsx(
                    active ? "bg-gray-100" : "",
                    "w-full block px-4 py-2 text-sm text-gray-700 text-left"
                  )}
                >
                  {p.t("navigation.tabs.profile.settings")}
                </button>
              )}
            </Menu.Item>

            <Menu.Item>
              {({ active }) => (
                <button
                  type="button"
                  onClick={soon}
                  className={clsx(
                    active ? "bg-gray-100" : "",
                    "w-full block px-4 py-2 text-sm text-gray-700  text-left"
                  )}
                >
                  {p.t("navigation.tabs.profile.needHelp")}
                </button>
              )}
            </Menu.Item>

            <Menu.Item>
              {({ active }) => (
                <button
                  type="button"
                  onClick={() => navigate("/logout")}
                  className={clsx(
                    active ? "bg-gray-100" : "",
                    "w-full block px-4 py-2 text-sm text-gray-700  text-left"
                  )}
                >
                  {p.t("navigation.tabs.profile.logout")}
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

ProfileDropdown.propTypes = {
  soon: PropTypes.func,
};

export default ProfileDropdown;
