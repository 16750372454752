import React, { useState } from "react";
import PropTypes from "prop-types";
import { useP } from "../../../../services/i18n";
import { copyToClipboard } from "../../../../utils/utils";
import { toast } from "react-hot-toast";
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useMutation } from "@apollo/client";
import { USE_ADVANTAGE } from "../../queries";
import Spinner from "../../../../components/Spinner";

function AdvantageGiftCodeBody({ onClose, advantage, getWalletData }) {
  const p = useP();

  const [useAdvantage, { loading: useAdvantageLoading }] =
    useMutation(USE_ADVANTAGE);

  const [giftCode, setGiftCode] = useState(advantage?.content?.giftCode);

  const onSubmit = async (e) => {
    e.preventDefault();

    const res = await useAdvantage({
      variables: {
        input: {
          advantageAttributeId: advantage.advantageAttributeId,
          nftId: advantage.nftId,
        },
      },
    });

    if (res.data?.useAdvantage?.success) {
      setGiftCode(res.data?.useAdvantage?.content.giftCode);
      getWalletData();
    } else {
      toast.error(p.t("accountPage.advantages.toasts.advantageUseError"));
    }
  };

  return (
    <form className="space-y-10" onSubmit={onSubmit}>
      <div className="space-y-2 text-center text-md">
        <div>{p.t("accountPage.advantages.giftCode")}</div>
        <div>
          <div
            className={clsx(
              `relative bg-slate-200 w-full rounded-md py-1.5 select-none text-sm`,
              giftCode && "cursor-pointer"
            )}
          >
            {giftCode ? (
              <div className="px-2">
                <div
                  className="flex flex-1 flex-row space-x-4 items-center"
                  onClick={() =>
                    copyToClipboard(giftCode, () => {
                      toast.success(
                        p.t("accountPage.advantages.toasts.giftCodeCopy")
                      );
                    })
                  }
                >
                  <div className="flex-1">{giftCode}</div>
                  <div className="absolute right-1 hover:bg-slate-100 rounded-lg p-1.5 cursor-pointer">
                    <ClipboardDocumentIcon className="w-4 h-4 text-slate-600 hover:text-blue-600 cursor-pointer" />
                  </div>
                </div>
              </div>
            ) : (
              <div className="py-0.5">* * * *</div>
            )}
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6 flex flex-col space-y-2 md:flex-row md:space-y-0 md:space-x-3">
        <button
          type="button"
          className="mt-3 flex-1 justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-slate-700 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
          onClick={onClose}
        >
          {p.t("commons.close")}
        </button>
        {!giftCode && (
          <button
            type="submit"
            disabled={useAdvantageLoading}
            className="flex-1 justify-center rounded-md border border-transparent bg-slate-900 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-slate-800 focus:outline-none focus:ring-2 focus:ring-slate-700 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
          >
            {useAdvantageLoading ? (
              <>
                <Spinner color="white" size="h-4 w-4" borderWidth="sm" />
              </>
            ) : (
              p.t("commons.reveal")
            )}
          </button>
        )}
      </div>
    </form>
  );
}

AdvantageGiftCodeBody.propTypes = {
  onClose: PropTypes.func,
  advantage: PropTypes.object,
  getWalletData: PropTypes.func,
};

export default AdvantageGiftCodeBody;
