import { gql } from "@apollo/client";

const IS_LOGGED_IN = gql`
  query IsLoggedIn {
    isLoggedIn {
      success
      loggedInUser {
        uuid
        email
        addresses
        newsletter
      }
    }
  }
`;

const SUBSCRIBE_TO_NEWSLETTER = gql`
  mutation SubscribeToNewsletter {
    subscribeToNewsletter {
      error
      message
      success
    }
  }
`;

export { IS_LOGGED_IN, SUBSCRIBE_TO_NEWSLETTER };
