// import { HeartIcon } from "@heroicons/react/24/solid";
import React from "react";
import ClaimForm from "./ClaimForm.jsx/ClaimForm";

import { useClaimPage } from "./UseClaimPageContext";
import { claimPageContext } from "./ClaimPageContext";

function ClaimPage() {
  const claimPage = useClaimPage();
  return (
    <>
      <claimPageContext.Provider value={claimPage}>
        <div className="flex-1 flex flex-col justify-center items-center bg-slate-50">
          <div className="flex flex-col h-full md:h-fit">
            <div className="flex-1 flex flex-col justify-center items-center md:py-10">
              <div className="h-full w-full md:max-w-xl md:h-full  md:shadow-xl md:rounded-3xl bg-white py-6 px-2">
                <div className="h-full w-full px-4 sm:px-4 lg:px-8 py-2 md:py-4 space-y-10">
                  <ClaimForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </claimPageContext.Provider>
    </>
  );
}

export default ClaimPage;
