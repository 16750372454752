import React, { useState } from "react";

import { XMarkIcon } from "@heroicons/react/24/solid";

import PropTypes from "prop-types";
import { useEffect } from "react";

export default function SearchBar({
  placeholder,
  totalItems = [],
  setFilteredItems,
  statusFilter = [],
}) {
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    const activeStatus = statusFilter
      .filter((item) => item.toggled)
      .map((item) => item.value);

    if (totalItems) {
      const filteredItems = totalItems.filter((item) => {
        let searchMatch = true;
        if (searchValue) {
          const tokenMatch = item.token
            .toLowerCase()
            .includes(searchValue.toLowerCase());
          const sendToMatch = item.sendTo
            ? item.sendTo.toLowerCase().includes(searchValue.toLowerCase())
            : false;
          const claimedByMatch = item.claimedBy
            ? item.claimedBy.toLowerCase().includes(searchValue.toLowerCase())
            : false;

          searchMatch = tokenMatch || sendToMatch || claimedByMatch;
        }

        const statusFilterMatch = activeStatus.length
          ? activeStatus.includes(item.status.code)
          : false;

        return searchMatch && statusFilterMatch;
      });

      setFilteredItems(filteredItems);
    }
  }, [searchValue, statusFilter, totalItems]);

  return (
    <div>
      <div className="flex rounded-md shadow-sm">
        <div className="relative flex flex-grow items-stretch focus-within:z-10">
          <input
            type="text"
            name="search"
            id="search"
            className="block w-full rounded-none rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            placeholder={placeholder}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
        <button
          type="button"
          onClick={() => setSearchValue("")}
          className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          <XMarkIcon
            className="-ml-0.5 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </button>
      </div>
    </div>
  );
}

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  totalItems: PropTypes.array,
  setFilteredItems: PropTypes.func,
  statusFilter: PropTypes.array,
};
