import React from "react";

import PropTypes from "prop-types";
import { useP } from "../services/i18n";

export default function Progress({ title, current, max, unlimited }) {
  const p = useP();

  const getWidth = () => {
    return unlimited ? 10 : (max / current) * 100;
  };

  return (
    <div>
      {/* <h4 className="sr-only">Status</h4> */}
      <p className="text-sm font-medium text-gray-900">
        <span className="text-blue-600 font-semibold">{title}</span> {current} /{" "}
        {unlimited ? p.t("progress.unlimited") : max}
      </p>
      <div className="mt-2" aria-hidden="true">
        <div className="overflow-hidden rounded-full bg-gray-200">
          <div
            className="h-2 rounded-full bg-blue-600"
            style={{ width: `${getWidth()}%` }}
          />
        </div>
      </div>
    </div>
  );
}

Progress.propTypes = {
  title: PropTypes.string,
  current: PropTypes.number,
  max: PropTypes.number,
  unlimited: PropTypes.bool,
};
