import React, { useState } from "react";
import PropTypes from "prop-types";
import { useP } from "../../../../services/i18n";
import Input from "../../../../components/Input";
import { useMutation } from "@apollo/client";
import { USE_ADVANTAGE } from "../../queries";
import { toast } from "react-hot-toast";
import Spinner from "../../../../components/Spinner";
import { STATUS_ACTIVE } from "../../../../utils/constants";

function AdvantageCatapultInviteBody({ advantage, onClose, getWalletData }) {
  const p = useP();

  const [inviteEmail, setInviteEmail] = useState("");

  const [useAdvantage, { loading: useAdvantageLoading }] =
    useMutation(USE_ADVANTAGE);

  const onSubmit = async (e) => {
    e.preventDefault();

    const res = await useAdvantage({
      variables: {
        input: {
          advantageAttributeId: advantage.advantageAttributeId,
          content: {
            sendTo: inviteEmail,
          },
          nftId: advantage.nftId,
        },
      },
    });

    if (res.data?.useAdvantage?.success) {
      toast.success(p.t("accountPage.advantages.toasts.inviteSended"));
      getWalletData();
    } else {
      toast.error(p.t("accountPage.advantages.toasts.advantageUseError"));
    }

    onClose();
  };

  return (
    <form className="space-y-10" onSubmit={onSubmit}>
      <div className="space-y-4 text-center text-md">
        <div>
          {advantage.statusCode === STATUS_ACTIVE ? (
            <>
              <div>{p.t("accountPage.advantages.inviteEmail")}</div>
              <Input
                type="email"
                value={inviteEmail}
                setValue={setInviteEmail}
                required={true}
              />
            </>
          ) : (
            <div className="space-y-2">
              <div>{p.t("accountPage.advantages.inviteEmailUsed")}</div>
              <div className="font-semibold">{advantage.content.sendTo}</div>
            </div>
          )}
        </div>
      </div>
      <div className="mt-5 sm:mt-6 flex flex-col space-y-2 md:flex-row md:space-y-0 md:space-x-3">
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-slate-700 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
          onClick={onClose}
        >
          {p.t("commons.close")}
        </button>
        {advantage.statusCode === STATUS_ACTIVE && (
          <button
            type="submit"
            disabled={useAdvantageLoading}
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-slate-900 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-slate-800 focus:outline-none focus:ring-2 focus:ring-slate-700 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
          >
            {useAdvantageLoading ? (
              <>
                <Spinner color="white" size="h-4 w-4" borderWidth="sm" />
              </>
            ) : (
              p.t("commons.send")
            )}
          </button>
        )}
      </div>
    </form>
  );
}

AdvantageCatapultInviteBody.propTypes = {
  onClose: PropTypes.func,
  advantage: PropTypes.object,
  nftId: PropTypes.string,
  getWalletData: PropTypes.func,
};

export default AdvantageCatapultInviteBody;
