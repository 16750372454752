import React, { useContext } from "react";
import Input from "../../../../components/Input";
import { useP } from "../../../../services/i18n";
import { claimPageContext } from "../../ClaimPageContext";

function WalletStep() {
  const p = useP();

  const { walletAddress, setWalletAddress } = useContext(claimPageContext);
  return (
    <div className="w-full space-y-12">
      <div className="w-full">
        <p className="text-lg text-gray-500 text-justify">
          {p.t("claimPage.steps.wallet.title")}
        </p>
      </div>
      <div className="space-y-4">
        <div className="">
          <Input
            type="text"
            name={"wallet-address"}
            label={p.t("claimPage.steps.wallet.fieldWallet", {
              network: "Polygon",
            })}
            value={walletAddress}
            setValue={setWalletAddress}
          />
        </div>
      </div>
      <div className="w-full mb-12 space-y-4">
        <p className="text-md italic text-gray-500">
          {p.t("claimPage.steps.wallet.subtitle")}{" "}
          <a
            href="#"
            target="_blank"
            className="font-semibold italic hover:text-blue-700 underline"
          >
            {p.t("claimPage.steps.wallet.needHelpLink")}
          </a>
        </p>
      </div>
    </div>
  );
}

export default WalletStep;
