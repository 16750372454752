import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useP } from "../../../../../services/i18n";

import PropTypes from "prop-types";
import {
  copyToClipboard,
  downloadQRCode,
  getMinifiedKey,
} from "../../../../../utils/utils";
import {
  ArrowPathIcon,
  ArrowUpTrayIcon,
  ClipboardDocumentIcon,
} from "@heroicons/react/24/outline";
import { toast } from "react-hot-toast";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CREATE_CLAIM_CODE,
  CREATE_CLAIM_CODES,
  DELETE_CLAIM_CODE,
  GET_CLAIM_CODES,
  REMOVE_CLAIM_CODE_TAG,
  SAVE_CLAIM_CODE,
  SEND_CLAIM_CODE,
} from "../../../queries";
import { Tooltip } from "react-tooltip";
import ActivationCodeItemMenu from "./ActivationCodeItemMenu";
import TagItemMenu from "./TagItemMenu";
import Progress from "../../../../../components/Progress";
import EditableText from "../../../../../components/EditabeText";
import ImportClaimCodeModal from "./ImportClaimCodeModal";
import clsx from "clsx";
import Pagination from "../../../../../components/Pagination";
import SearchBar from "../../../../../components/SearchBar";
import StatusButtonGroup from "../../../../../components/StatusButtonGroup";
import {
  STATUS_ACTIVE,
  STATUS_BURNED,
  STATUS_SENDED,
} from "../../../../../utils/constants";
import Spinner from "../../../../../components/Spinner";
import { QrCodeIcon } from "@heroicons/react/24/solid";

const STATUS_DATA = [
  {
    id: 0,
    value: STATUS_ACTIVE,
    toggled: true,
  },
  {
    id: 1,
    value: STATUS_SENDED,
    toggled: true,
  },
  {
    id: 2,
    value: STATUS_BURNED,
    toggled: true,
  },
];

export default function ActivationCodes({ selectedCollection }) {
  const p = useP();

  const [openImportModal, setOpenImportModal] = useState(false);

  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedClaimCodes, setSelectedClaimCodes] = useState([]);
  const [paginatedItems, setPaginatedItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);

  const [statusList, setStatusList] = useState(STATUS_DATA);

  const [claimCodeLoading, setClaimCodeLoading] = useState(false);

  const [
    getClaimCodes,
    {
      data: claimCodesData,
      // loading: claimCodeLoading,
      // eslint-disable-next-line no-unused-vars
      error: claimCodesDataError,
    },
  ] = useLazyQuery(GET_CLAIM_CODES, {
    fetchPolicy: "network-only",

    onCompleted: () => {
      setClaimCodeLoading(false);

      resetToggle();
    },
  });

  const [saveClaimCode] = useMutation(SAVE_CLAIM_CODE);

  const [createClaimCode] = useMutation(CREATE_CLAIM_CODE);

  const [createClaimCodes] = useMutation(CREATE_CLAIM_CODES);

  const [deleteClaimCode] = useMutation(DELETE_CLAIM_CODE);

  const [sendClaimCodes] = useMutation(SEND_CLAIM_CODE);

  const [removeClaimCodeTag] = useMutation(REMOVE_CLAIM_CODE_TAG);

  const loadClaimCodes = async () => {
    setClaimCodeLoading(true);

    getClaimCodes({
      variables: {
        code: selectedCollection.code,
      },
    });
  };

  const importContacts = async (datas) => {
    await createClaimCodes({
      variables: {
        code: selectedCollection.code,
        datas,
      },
    });

    loadClaimCodes();

    setOpenImportModal(false);
  };

  const onClickNew = async (global) => {
    await createClaimCode({
      variables: {
        code: selectedCollection.code,
        global,
      },
    });
    loadClaimCodes();
  };

  const onClickDelete = async (claimCode) => {
    await deleteClaimCode({
      variables: {
        token: claimCode.token,
      },
    });
    loadClaimCodes();
  };

  const onClickActivate = async (claimCode) => {
    await saveClaimCode({
      variables: {
        params: {
          token: claimCode.token,
          statusCode: "ACTIVE",
        },
      },
    });
    loadClaimCodes();
  };

  const onClickDesactivate = async (claimCode) => {
    await saveClaimCode({
      variables: {
        params: {
          token: claimCode.token,
          statusCode: "INACTIVE",
        },
      },
    });
    loadClaimCodes();
  };

  const onClickSendAll = async (claimCodes) => {
    const reduced = claimCodes
      .filter((claimCode) => !!claimCode.sendTo && !claimCode.global)
      .map((claimCode) => claimCode.token);

    await sendClaimCodes({
      variables: {
        tokens: reduced,
      },
    });

    toast.success(
      p.t(
        "accountPage.owner.collectionDetails.activationCodes.tokenSendedSuccess"
      )
    );
  };

  const onClickSend = async (claimCode) => {
    await onClickSendAll([claimCode]);
  };

  const onSubmitSendTo = async (claimCode, value) => {
    await saveClaimCode({
      variables: {
        params: {
          token: claimCode.token,
          sendTo: value,
        },
      },
    });
    loadClaimCodes();
  };

  const resetToggle = () => {
    setSelectedClaimCodes([]);
    setChecked(false);
    setIndeterminate(false);
  };

  const onChangePaginatedItems = (paginated) => {
    setPaginatedItems(paginated);
    resetToggle();
  };

  const toggleAll = () => {
    const filtered = paginatedItems.filter(
      (claimCode) => !!claimCode.sendTo && !claimCode.global
    );

    setSelectedClaimCodes(checked || indeterminate ? [] : filtered);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  };

  useLayoutEffect(() => {
    const isIndeterminate =
      selectedClaimCodes.length > 0 &&
      selectedClaimCodes.length < paginatedItems.length;
    setChecked(selectedClaimCodes.length === paginatedItems.length);
    setIndeterminate(isIndeterminate);
    checkbox.current.indeterminate = isIndeterminate;
  }, [selectedClaimCodes]);

  // const onClickEdit = () => {
  //   alert("EDIT");
  // };

  useEffect(() => {
    if (selectedCollection?.code) {
      loadClaimCodes();
    }
  }, [selectedCollection]);

  const onRemoveTag = async (claimCode, tag) => {
    await removeClaimCodeTag({
      variables: {
        claimCode: claimCode.token,
        tagId: tag.id,
      },
    });

    loadClaimCodes();
  };

  const getStatusBadge = (code, global) => {
    let res = [];

    if (global) {
      res.push(
        <span className="inline-flex rounded-full bg-slate-700 px-2 text-xs font-semibold leading-5 text-slate-200">
          {p.t("status.global")}
        </span>
      );
    }

    switch (code) {
      case "BURNED":
        res.push(
          <span className="inline-flex rounded-full bg-amber-100 px-2 text-xs font-semibold leading-5 text-amber-800">
            {p.t("status.burned")}
          </span>
        );
        break;

      case "SENDED":
        res.push(
          <span className="inline-flex rounded-full bg-blue-100 px-2 text-xs font-semibold leading-5 text-blue-800">
            {p.t("status.sended")}
          </span>
        );
        break;

      case "ACTIVE":
        res.push(
          <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
            {p.t("status.active")}
          </span>
        );
        break;

      default:
        res.push(
          <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
            {p.t("status.inactive")}
          </span>
        );
        break;
    }

    return res;
  };
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            {p.t("accountPage.owner.collectionDetails.activationCodes.title")}
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            {p.t(
              "accountPage.owner.collectionDetails.activationCodes.description"
            )}
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 flex flex-row space-x-2">
          <div
            id="refresh-button"
            onClick={loadClaimCodes}
            className="cursor-pointer inline-flex items-center justify-center rounded-md border border-transparent bg-slate-200 px-2 py-1 text-sm font-medium text-white shadow-sm hover:bg-slate-300  sm:w-auto"
          >
            <ArrowPathIcon className="w-4 h-4 text-slate-700" />
          </div>

          <div
            id="import-button"
            onClick={() => {
              setOpenImportModal(true);
            }}
            className="cursor-pointer inline-flex items-center justify-center rounded-md border border-transparent bg-slate-200 px-2 py-1 text-sm font-medium text-white shadow-sm hover:bg-slate-300  sm:w-auto"
          >
            <ArrowUpTrayIcon className="w-4 h-4 text-slate-700" />
          </div>
          <div
            onClick={onClickNew}
            className="cursor-pointer inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-2 py-1 text-sm font-medium text-white shadow-sm hover:bg-blue-700  sm:w-auto"
          >
            {p.t(
              "accountPage.owner.collectionDetails.activationCodes.newToken"
            )}
          </div>
          <div
            onClick={() => onClickNew(true)}
            className="cursor-pointer inline-flex items-center justify-center rounded-md border border-transparent bg-slate-600 px-2 py-1 text-sm font-medium text-white shadow-sm hover:bg-slate-700  sm:w-auto"
          >
            {p.t(
              "accountPage.owner.collectionDetails.activationCodes.newTokenGlobal"
            )}
          </div>
        </div>
      </div>
      <div className="mt-8">
        <Progress
          title={p.t(
            "accountPage.owner.collectionDetails.activationCodes.credits"
          )}
          current={claimCodesData?.claimCodes.length}
          unlimited={true}
        />
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 space-y-4">
            {selectedClaimCodes.length > 0 && (
              <div className="flex justify-end items-center space-x-3 bg-white sm:left-12">
                <button
                  type="button"
                  onClick={() => onClickSendAll(selectedClaimCodes)}
                  className="cursor-pointer inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-2 py-1 text-sm font-medium text-white shadow-sm hover:bg-blue-700  sm:w-auto"
                >
                  {p.t(
                    "accountPage.owner.collectionDetails.activationCodes.sendAll"
                  )}
                </button>
              </div>
            )}
            <div className="flex space-x-4">
              <div className="flex-1">
                <SearchBar
                  placeholder={p.t(
                    "accountPage.owner.collectionDetails.activationCodes.searchPlaceholder"
                  )}
                  totalItems={claimCodesData?.claimCodes}
                  setFilteredItems={setFilteredItems}
                  statusFilter={statusList}
                />
              </div>

              <StatusButtonGroup
                statusList={statusList}
                setStatusList={(value) => setStatusList(value)}
              />
            </div>
            <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <div className="table-wrp block max-h-96 ">
                <table className="min-w-full divide-y divide-gray-300 overflow-auto">
                  <thead className="bg-gray-50 sticky top-0 z-20">
                    <tr className="">
                      <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                        <input
                          type="checkbox"
                          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                          ref={checkbox}
                          checked={checked}
                          onChange={toggleAll}
                        />
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        {p.t(
                          "accountPage.owner.collectionDetails.activationCodes.token"
                        )}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        {p.t(
                          "accountPage.owner.collectionDetails.activationCodes.sendedTo"
                        )}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        {p.t(
                          "accountPage.owner.collectionDetails.activationCodes.claimedBy"
                        )}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        {p.t(
                          "accountPage.owner.collectionDetails.activationCodes.tags"
                        )}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        {p.t(
                          "accountPage.owner.collectionDetails.activationCodes.status"
                        )}
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                      >
                        <span className="sr-only">
                          {p.t(
                            "accountPage.owner.collectionDetails.activationCodes.edit"
                          )}
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white max-h-96 overflow-y-auto">
                    {paginatedItems.length > 0 ? (
                      paginatedItems.map((claimToken, index) => (
                        <tr key={index} className="overflow-hidden">
                          <td className="relative px-7 sm:w-12 sm:px-6">
                            {selectedClaimCodes.find(
                              (record) => record.token === claimToken.token
                            ) && (
                              <div className="absolute inset-y-0 left-0 w-0.5 bg-blue-600" />
                            )}
                            <input
                              type="checkbox"
                              className={clsx(
                                "absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500",
                                (!claimToken.sendTo || claimToken.global) &&
                                  "hidden"
                              )}
                              value={claimToken}
                              checked={selectedClaimCodes.find(
                                (record) => record.token === claimToken.token
                              )}
                              onChange={(e) =>
                                setSelectedClaimCodes(
                                  e.target.checked
                                    ? [...selectedClaimCodes, claimToken]
                                    : selectedClaimCodes.filter(
                                        (p) => p !== claimToken
                                      )
                                )
                              }
                            />
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                            <div className="flex items-center">
                              <div className="flex flex-row space-x-2">
                                <div
                                  className="font-medium text-gray-900 flex flex-row space-x-4 items-center"
                                  onClick={() =>
                                    copyToClipboard(claimToken.token, () => {
                                      toast.success(
                                        p.t(
                                          "accountPage.owner.collectionDetails.activationCodes.tokenClipboardSuccess"
                                        )
                                      );
                                    })
                                  }
                                >
                                  <span className="cursor-pointer">
                                    {getMinifiedKey(claimToken.token)}
                                  </span>
                                  <span className="hover:bg-slate-100 rounded-lg p-1.5 cursor-pointer">
                                    <ClipboardDocumentIcon className="w-4 h-4 text-slate-600 hover:text-blue-600 cursor-pointer" />
                                  </span>
                                </div>
                                {claimToken.global && (
                                  <div
                                    className="hover:bg-slate-100 rounded-lg p-1.5 cursor-pointer"
                                    onClick={() =>
                                      downloadQRCode(
                                        `${location.origin}/claim?key=${claimToken.token}`,
                                        `${selectedCollection.code}_${claimToken.token}`
                                      )
                                    }
                                  >
                                    <QrCodeIcon className="w-4 h-4 text-slate-600 hover:text-blue-600 cursor-pointer" />
                                  </div>
                                )}
                              </div>
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <div className="text-gray-900">
                              <EditableText
                                initialValue={claimToken.sendTo}
                                emptyValue={p.t("commons.nothing")}
                                onSubmit={(value) =>
                                  onSubmitSendTo(claimToken, value)
                                }
                                readOnly={claimToken.status.code !== "ACTIVE"}
                              />
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <div className="text-gray-900">
                              {claimToken.receivers.length ? (
                                <ul className="space-y-1">
                                  {claimToken.receivers.map(
                                    (receiver, index) => (
                                      <li key={index}>{receiver.email}</li>
                                    )
                                  )}
                                </ul>
                              ) : (
                                p.t("commons.nothing")
                              )}
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <TagItemMenu
                              selectedCollection={selectedCollection}
                              claimToken={claimToken}
                              onRemoveTag={onRemoveTag}
                              loadClaimCodes={loadClaimCodes}
                            />
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <div className="flex flex-col space-y-2 items-center">
                              {getStatusBadge(
                                claimToken?.status?.code,
                                claimToken.global
                              )}
                            </div>
                          </td>
                          <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            {claimToken.status.code !== "BURNED" &&
                              !claimToken.global && (
                                <ActivationCodeItemMenu
                                  claimToken={claimToken}
                                  onClickActivate={() =>
                                    onClickActivate(claimToken)
                                  }
                                  onClickDesactivate={() =>
                                    onClickDesactivate(claimToken)
                                  }
                                  onClickDelete={() =>
                                    onClickDelete(claimToken)
                                  }
                                  onClickSend={() => onClickSend(claimToken)}
                                />
                              )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <div className="p-4 text-sm text-slate-700">
                        {claimCodeLoading ? (
                          <Spinner />
                        ) : (
                          p.t(
                            "accountPage.owner.collectionDetails.activationCodes.noCode"
                          )
                        )}
                      </div>
                    )}
                  </tbody>
                </table>
              </div>
              <Pagination
                length={50}
                totalItems={filteredItems}
                paginatedItems={paginatedItems}
                setPaginatedItems={onChangePaginatedItems}
              />
            </div>
          </div>
        </div>
      </div>
      <Tooltip
        anchorId={`refresh-button`}
        content={p.t("commons.refresh")}
        place="bottom"
      />
      <Tooltip
        anchorId={`import-button`}
        content={p.t("commons.import")}
        place="bottom"
      />
      <ImportClaimCodeModal
        open={openImportModal}
        onClose={() => setOpenImportModal(false)}
        onClickImport={importContacts}
      />
    </div>
  );
}

ActivationCodes.propTypes = {
  selectedCollection: PropTypes.object,
};
