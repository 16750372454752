import React, { useContext, useState } from "react";
import { useP } from "../../../services/i18n";
import PropTypes from "prop-types";
import Input from "../../../components/Input";
import { AUTHENTIFY } from "../queries";
import { useMutation } from "@apollo/client";
import { GoogleLogin } from "@react-oauth/google";
import { ClientJS } from "clientjs";
import { authContext } from "../../../AuthContext";
import {
  FORM_LOADING_STATE,
  FORM_SUCCESS_STATE,
  FORM_WAITING_STATE,
} from "../../../utils/constants";
import DynamicButton from "../../../components/DynamicButton";

const client = new ClientJS();

function LoginFormModal({ toggleMode, goToRenewPassword }) {
  const p = useP();

  const { setToken, setAuthModaleStatus } = useContext(authContext);

  // eslint-disable-next-line no-unused-vars
  const [authentify, { data: authentifyData }] = useMutation(AUTHENTIFY);

  const [currentState, setCurrentState] = useState(FORM_WAITING_STATE);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState();

  const onSubmit = async (e) => {
    e.preventDefault();

    setCurrentState(FORM_LOADING_STATE);

    const fingerprint = client.getFingerprint();
    const userAgent = client.getUserAgent();

    const res = await authentify({
      variables: {
        auth: {
          email,
          password,
          type: "FORM",
          fingerprint: `${fingerprint}`,
          userAgent,
        },
      },
    });

    if (res?.data?.authentify?.success) {
      setCurrentState(FORM_SUCCESS_STATE);
      setToken(res?.data?.authentify?.token);
      setAuthModaleStatus("hidden");
    } else {
      setCurrentState(FORM_WAITING_STATE);
      setErrorMessage(res?.data?.authentify?.error);
    }
  };

  const onGoogleSuccess = async (data) => {
    const fingerprint = client.getFingerprint();
    const userAgent = client.getUserAgent();

    const res = await authentify({
      variables: {
        auth: {
          email: "oauth@google.com",
          token: data.credential,
          type: "GOOGLE",
          fingerprint: `${fingerprint}`,
          userAgent,
        },
      },
    });

    if (res?.data?.authentify?.success) {
      setCurrentState(FORM_SUCCESS_STATE);
      setToken(res?.data?.authentify?.token);
      setAuthModaleStatus("hidden");
    }
  };

  const onGoogleFailure = () => {};

  return (
    <form className="w-full space-y-10 text-left" onSubmit={onSubmit}>
      <div className="flex flex-col items-center justify-center w-full space-x-3">
        <div>
          <img src="/logo.png" className="w-12 h-w-12" />
        </div>
        <p className="text-xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-slate-800 to-slate-600">
          {p.t("navigation.authModal.title")}
        </p>
      </div>
      <div className="space-y-4">
        <Input
          type="email"
          name="email"
          label={p.t("navigation.authModal.fieldEmail")}
          value={email}
          setValue={setEmail}
          required={true}
        />
        <Input
          type="password"
          name="password"
          label={p.t("navigation.authModal.fieldPassword")}
          value={password}
          setValue={setPassword}
          required={true}
        />
      </div>

      {errorMessage && (
        <div className="text-red-700">{p.t(`errors.${errorMessage}`)}</div>
      )}

      <div className="space-y-8">
        <div className="space-y-2">
          <div className="inline-flex space-x-2 w-full">
            <DynamicButton
              type="submit"
              label={p.t("commons.continue")}
              currentState={currentState}
            />
          </div>

          <div className="w-full mb-12 space-y-4 text-center">
            <p className="text-md italic text-gray-500 hover:text-gray-600">
              <span
                href="#"
                target="_blank"
                className="cursor-pointer font-semibold italic "
                onClick={goToRenewPassword}
              >
                {p.t("navigation.authModal.signIn.forgotMyPassword")}{" "}
              </span>
            </p>
          </div>
        </div>

        <div className="mt-6">
          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="bg-white px-2 text-gray-500">
                {p.t("navigation.authModal.signIn.quickAccess")}
              </span>
            </div>
          </div>

          <div className="mt-6 flex w-full justify-center">
            <div>
              <GoogleLogin
                onSuccess={onGoogleSuccess}
                onFailure={onGoogleFailure}
              />
            </div>
          </div>
        </div>

        <div className="w-full mb-12 space-y-4 text-center">
          <p className="text-md italic text-gray-500">
            {p.t("navigation.authModal.signIn.doNotHaveAccountText")}{" "}
            <span
              href="#"
              target="_blank"
              className="cursor-pointer font-semibold italic text-blue-700 hover:text-blue-800"
              onClick={toggleMode}
            >
              {p.t("navigation.authModal.signIn.doNotHaveAccountLink")}{" "}
            </span>
          </p>
        </div>
      </div>
    </form>
  );
}

LoginFormModal.propTypes = {
  toggleMode: PropTypes.func,
  goToRenewPassword: PropTypes.func,
};

export default LoginFormModal;
