import React, { useContext } from "react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Input from "../../../../components/Input";
import { useP } from "../../../../services/i18n";
import { claimPageContext } from "../../ClaimPageContext";

function TokenStep() {
  const p = useP();

  const { activationCode, setActivationCode } = useContext(claimPageContext);

  const [queryParams] = useSearchParams();

  useEffect(() => {
    if (queryParams.get("key")) {
      setActivationCode(queryParams.get("key"));
    }
  }, []);

  return (
    <div className="w-full space-y-12">
      <div className="w-full">
        <p className="text-md text-gray-500 text-justify">
          {p.t("claimPage.steps.token.title")}
        </p>
      </div>
      <div className="space-y-4">
        <div className="">
          <Input
            type="text"
            name="activation-code"
            label={p.t("claimPage.steps.token.fieldActivationCode")}
            required={true}
            value={activationCode}
            setValue={setActivationCode}
            autoComplete="off"
          />
        </div>
      </div>
    </div>
  );
}

export default TokenStep;
