import React, { useState } from "react";

import PropTypes from "prop-types";
import Modal from "../../../../components/Modal";
import { useP } from "../../../../services/i18n";
import CollectionDetailsTabs from "./CollectionDetailsTabs";
import ActivationCodes from "./Tabs/ActivationCodes";
import {
  BookOpenIcon,
  ChartBarIcon,
  BeakerIcon,
  HomeIcon,
  KeyIcon,
} from "@heroicons/react/24/outline";
import CollectionInformations from "./Tabs/CollectionInformations";
import CollectionRessources from "./Tabs/ColectionRessources";
import CollectionMetadata from "./Tabs/ColectionMetadata";
import CollectionStats from "./Tabs/ColectionStats";

function CollectionDetails({ open, onClose, selectedCollection }) {
  const p = useP();

  const navigationData = [
    {
      code: "INFO",
      label: p.t("accountPage.owner.collectionDetails.navigation.info"),
      element: CollectionInformations,
      icon: HomeIcon,
      locked: true,
    },
    {
      code: "TOKENS",
      label: p.t("accountPage.owner.collectionDetails.navigation.claimTokens"),
      element: ActivationCodes,
      icon: KeyIcon,
      locked: false,
    },
    {
      code: "RESSOURCES",
      label: p.t("accountPage.owner.collectionDetails.navigation.ressources"),
      element: CollectionRessources,
      icon: BeakerIcon,
      locked: true,
    },
    {
      code: "METADATA",
      label: p.t("accountPage.owner.collectionDetails.navigation.metadata"),
      element: CollectionMetadata,
      icon: BookOpenIcon,
      locked: true,
    },
    {
      code: "STATS",
      label: p.t("accountPage.owner.collectionDetails.navigation.stats"),
      element: CollectionStats,
      icon: ChartBarIcon,
      locked: true,
    },
  ];

  const [selectedTab, setSelectedTab] = useState(navigationData[1]);

  const onSelectTab = (tab) => {
    setSelectedTab(tab);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        title={p.t(`accountPage.owner.collectionDetails.title`, {
          label: selectedCollection ? selectedCollection?.label : "",
        })}
        className="md:max-w-fit mx-6"
      >
        <div className="w-full flex flex-col md:flex-row">
          <div className="flex">
            <CollectionDetailsTabs
              navigation={navigationData}
              selectedTab={selectedTab}
              onSelectTab={onSelectTab}
            />
          </div>
          <div className="py-10">
            <selectedTab.element selectedCollection={selectedCollection} />
          </div>
        </div>
      </Modal>
    </>
  );
}

CollectionDetails.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  selectedCollection: PropTypes.object,
};

export default CollectionDetails;
