import PropTypes from "prop-types";
import clsx from "clsx";
import {
  STATUS_ACTIVE,
  STATUS_BURNED,
  STATUS_SENDED,
} from "../utils/constants";
import { useP } from "../services/i18n";

export default function StatusButtonGroup({ statusList, setStatusList }) {
  const p = useP();

  const getStatusLabel = (code) => {
    let res;

    switch (code) {
      case STATUS_ACTIVE:
        res = p.t("status.active");
        break;
      case STATUS_BURNED:
        res = p.t("status.burned");
        break;

      case STATUS_SENDED:
        res = p.t("status.sended");
        break;

      default:
        break;
    }

    return res;
  };

  const onToggleStatus = (record) => {
    const toggledCount = statusList.filter((item) => item.toggled).length;

    if (toggledCount === 1 && record.toggled) return;

    const toggled = statusList.map((item) => {
      let res = item;

      if (item.id === record.id) {
        res.toggled = !res.toggled;
      }

      return res;
    });

    setStatusList([...toggled]);
  };

  return (
    <span className="isolate inline-flex rounded-md shadow-sm">
      {statusList.map((item) => (
        <button
          key={item.id}
          type="button"
          onClick={() => onToggleStatus(item)}
          className={clsx(
            "relative inline-flex items-center  px-3 py-2 text-sm font-semibold  ring-1 ring-inset  focus:z-10 cursor-pointer",
            item.toggled
              ? "bg-blue-600 text-white ring-blue-700 hover:bg-blue-700"
              : "bg-white text-gray-900 ring-gray-300 hover:bg-gray-50",
            item.id == 0 && "rounded-l-md",
            item.id === statusList.length - 1 && "rounded-r-md"
          )}
        >
          {getStatusLabel(item.value)}
        </button>
      ))}
    </span>
  );
}

StatusButtonGroup.propTypes = {
  statusList: PropTypes.array,
  setStatusList: PropTypes.func,
};
